<template>

    <!-- Main content -->
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Garantia</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">

          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">

      <div class="row">

<div class="col-12">


            <div class="card ">
              <div class="card-header">


                 <div class="form-group row">
                   <div class="col-md-6">
                     <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tienda</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar2">
                                    <option value="0" disabled>Seleccione</option>
                                            <option v-for="lugar in arrayLugar2" :key="lugar.id" :value="lugar.id"  v-text="lugar.nombre"></option>
                                    </select>

                                 </div>
                     <div class="input-group">

                      <!--
                        @keyup="listargarantia(1,buscar,criterio,idlugar2)"
                        -->

                       <input type="text" v-model="buscar" @keyup.enter="listargarantia(1,buscar,criterio,idlugar2)" class="form-control" placeholder="S/N">
                       <button type="submit" class="btn btn-primary" @click="listargarantia(1,buscar,criterio,idlugar2)"><i class="fas fa-search"></i>&nbsp;Buscar</button>
                     </div>
                   </div>
                 </div>

               </div>


              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Numero Comprobante</th>
                    <th>Fecha_hora</th>
                    <th>Estado</th>
                    <th>Codigo</th>
                    <th>Nombre</th>
                    <th>Cliente</th>



                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(garantia, index) in arraygarantia" :key="garantia.id" >
                    <td>{{(index + 1)}}</td>



                                     <td v-text="garantia.num_comprobante"></td>
                                     <td v-text="garantia.fecha_hora"></td>
                                     <td v-text="garantia.estado"></td>
                                      <td v-text="garantia.codigo"></td>
                                    <td v-text="garantia.nombre"></td>
                                    <td v-text="garantia.cliente"></td>



                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>






<div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">

                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Nombre:</label>
                     <input type="text" class="form-control" @blur="duplicado()"  v-model="nombre">
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Descripcion:</label>
                      <input type="text" class="form-control"  v-model="descripcion" >
                  </div>


                   <div v-show="errorgarantia" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjgarantia" :key="error" v-text="error">
                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row  div-error">
                    <p class="text-center text-error">La garantia ya Existe!</p>
                  </div>
                  </form>
                 </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
              <button type="button" v-if="tipoAccion==1" :disabled = "deshabilitar_boton==1"  class="btn btn-primary" @click="registrargarantia()">Guardar</button>
               <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizargarantia()">Actualizar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




      <!-- /.row -->
    </div><!-- /.container-fluid -->

  <!-- /.content -->
</template>
<script>




export default {

       data (){
            return {
                garantia_id: 0,
                nombre : '',
                descripcion : '',
                arraygarantia : [],
                arrayLugar2 : [],
                idlugar2:1,
                modal : 0,
                tituloModal : '',
                tipoAccion : 0,
                errorgarantia : 0,
                errorMostrarMsjgarantia : [],
                 'mensaje':'',
                 deshabilitar_boton:0,
                'val1':0,
                    'val2':0,
                    'val3':0,
                    'val4':0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,

                },
                offset : 3,
                criterio : 'VentaContado',
                buscar : ''
            }
        },




        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;

            }
        },
        methods : {

            listargarantia (page,buscar,criterio,idlugar2){
              /*
              console.log(buscar);
              console.log(criterio);
              console.log(idlugar2);
              */

                let me=this;
                 var url= '/GarantiaAdmin?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio + '&idlugar2='+ idlugar2;
                axios.get(url).then(function (response) {
                  //console.log(response);
                    var respuesta= response.data;
                    me.arraygarantia = respuesta.garantia.data;
                   //console.log(me.arraygarantia);
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },



            cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listargarantia(page,buscar,criterio,this.idlugar2);
            },





                   selectLugar2(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar2 = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },





        },
        mounted() {
          this.selectLugar2();

        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>
