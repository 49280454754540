<template>


    <!-- Main content -->
 <div class="content">

 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">


            <h1 class="m-0 text-dark">Producto</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">

          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>


    <div class="container-fluid">

      <div class="row">

<div class="col-12">


            <div class="card ">
              <div class="card-header">
                <form action="">
                 <button type="button" @click="abrirModal('producto','registrar')" class="btn btn-success mb-2"><i class="fas fa-plus-square"></i>&nbsp;Nuevo</button>&nbsp;
                  <button style="display: none;" type="button" @click="cargarPdf()" class="btn btn-info mb-2"><i class="fas fa-file-pdf"></i>&nbsp;Reporte</button>&nbsp;
                 <div class="form-group row">
                   <div class="col-md-6">
                     <div class="input-group">
                       <select name="" id="" class="form-control col-md-3" v-model="criterio">
                         <option value="codigo">Codigo</option>
                         <option value="nombre">Nombre</option>
                         <option value="descripcion">Descripcion</option>
                       </select>
                       <input type="text" class="form-control" placeholder="Buscar" v-model="buscar" @keyup.enter="listarProducto(1,buscar,criterio)">
                       <button type="submit" class="btn btn-primary" @click="listarProducto(1,buscar,criterio)"><i class="fas fa-search"></i>&nbsp;Buscar</button>
                     </div>
                   </div>
                 </div>
                 </form>

               </div>


              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Opciones</th>
                    <th>Imagen</th>
                    <th>Codigo</th>
                    <th>Categoria</th>


                    <th>Nombre</th>
                    <th>Costo_Anterior</th>
                    <th>Precio_Costo</th>
                    <th>Precio_venta</th>
                    <th>Precio_venta2</th>
                    <th>Descripcion</th>
                    <th>Minimo</th>
                    <th>Estado</th>


                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(producto,index) in arrayProducto" :key="producto.id" >
                    <td>
                      {{index+1}}
                    </td>
                    <td>
                       <button type="button" @click="abrirModal('producto','actualizar',producto)" class="btn btn-warning btn-sm"><i class="fas fa-edit"></i></button>&nbsp;
                      <template v-if="producto.condicion">
                        <button type="button" class="btn btn-danger btn-sm" @click="desactivarProducto(producto.id)">
                          <i class="fas fa-trash-alt"></i>
                          </button>
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-info btn-sm" @click="activarProducto(producto.id)"><i class="far fa-thumbs-up"></i></button>
                      </template>

                    </td>
                    <td>
                      <div class="contentimg">
                         <img :src="url+producto.img" class="imgcrud" alt="">
                      </div>

                      </td>

                                     <td  v-text="producto.codigo"></td>
                                    <td v-text="producto.nombre_categoria"></td>



                                    <td  v-text="producto.nombre"></td>

                                   <td v-text="producto.costo_anterior"></td>
                                    <td v-text="producto.precio_costo"></td>
                                    <td v-text="producto.precio_venta"></td>
                                    <td v-text="producto.precio_venta2"></td>


                                    <td v-text="producto.descripcion"></td>


                                    <td  v-text="producto.minimo"></td>

                                   <td>
                                       <div v-if="producto.condicion">
                                           <span class="badge badge-success"> Activo </span>
                                       </div>
                                       <div v-else>
                                          <span class="badge badge-danger"> Desactivado </span>
                                       </div>

                                    </td>

                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio,idlugar)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio,idlugar)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio,idlugar)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>






<div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal2}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal2"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">



                   <div class="card-body table-responsive">
                <table  class="table table-bordered table-striped">
                  <thead>
                  <tr>


                    <th>Nombre</th>
                    <th>Stock</th>
                    <th>Descripcion</th>
                    <th>Minimo</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="producto in arrayProducto" :key="producto.id" >


                                    <td v-if="producto.stock <= producto.minimo"  v-text="producto.nombre"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                    <td v-if="producto.stock <= producto.minimo" v-text="producto.descripcion"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.minimo"></td>




                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>

            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




<div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                 <div class="container-fluid">
                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                   <div class="form-group row">
                     <label class="col-md-3 form-control-label" >Imagen: </label>
                     <img :src="img" alt="" style="width:100%">
                      <input type="file" name="img" accept=".png, .jpg, .jpeg" :maxFileSiza="2048" @change="GetImage"  class="btn btn-info btn-sm">
                  </div>

                    <div class="form-group row">
                      <label class="col-md-3 form-control-label" >Categoria </label>
                         <select class="form-control" v-model="idcategoria">
                            <option value="0" disabled>Seleccione</option>
                            <option v-for="categoria in arrayCategoria" :key="categoria.id" :value="categoria.id" v-text="categoria.nombre"></option>
                        </select>
                    </div>
<!--
                    <div class="form-group row">
                      <label class="col-md-3 form-control-label" >Genero </label>
                         <select class="form-control" v-model="idgenero">
                            <option value="0" disabled>Seleccione</option>
                            <option v-for="genero in arrayGenero" :key="genero.id" :value="genero.id" v-text="genero.nombre"></option>
                        </select>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-3 form-control-label" >Diseño </label>
                         <select class="form-control" v-model="iddiseno">
                            <option value="0" disabled>Seleccione</option>
                            <option v-for="diseno in arrayDiseno" :key="diseno.id" :value="diseno.id" v-text="diseno.nombre"></option>
                        </select>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-3 form-control-label" >Tela </label>
                         <select class="form-control" v-model="idtela">
                            <option value="0" disabled>Seleccione</option>
                            <option v-for="tela in arrayTela" :key="tela.id" :value="tela.id" v-text="tela.nombre"></option>
                        </select>
                    </div>

                     <div class="form-group row">
                      <label class="col-md-3 form-control-label" >Color </label>
                         <select class="form-control" v-model="idcolor">
                            <option value="0" disabled>Seleccione</option>
                            <option v-for="color in arrayColor" :key="color.id" :value="color.id" v-text="color.nombre"></option>
                        </select>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-3 form-control-label" >Talla </label>
                         <select class="form-control" v-model="idtalla">
                            <option value="0" disabled>Seleccione</option>
                            <option v-for="talla in arrayTalla" :key="talla.id" :value="talla.id" v-text="talla.nombre"></option>
                        </select>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-3 form-control-label" >Lote </label>
                         <select class="form-control" v-model="idlote">
                            <option value="0" disabled>Seleccione</option>
                            <option v-for="lote in arrayLote" :key="lote.id" :value="lote.id" v-text="lote.nombre"></option>
                        </select>
                    </div>
-->
                    <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Codigo:</label>
                     <input type="text" class="form-control" @blur="duplicado()"  v-model="codigo">
                  </div>

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Nombre:</label>
                     <input type="text" class="form-control"   v-model="nombre">
                  </div>


                   <div class="form-group row">
                     <label for="message-text" class="col-form-label">Precio costo:</label>
                      <input type="number" class="form-control"  v-model="precio_costo" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Precio venta:</label>
                      <input type="number" class="form-control"  v-model="precio_venta" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Precio venta2:</label>
                      <input type="number" class="form-control"  v-model="precio_venta2" >
                  </div>


                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Descripción:</label>
                      <input type="text" class="form-control"  v-model="descripcion" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Minimo:</label>
                      <input type="number" min="0" step="1" class="form-control"  v-model="minimo" >
                  </div>



                   <div v-show="errorProducto" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjProducto" :key="error" v-text="error">
                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row  div-error">
                    <p class="text-center text-error">El Producto ya Existe!</p>
                  </div>
                  </form>
                 </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Close</button>
              <button type="button" v-if="tipoAccion==1" :disabled = "deshabilitar_boton==1"  class="btn btn-primary" @click="registrarProducto()">Guardar</button>
               <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizarProducto()">Actualizar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




      <!-- /.row -->
    </div><!-- /.container-fluid -->

  <!-- /.content -->
</template>
<script>




export default {

       data (){
            return {
                producto_id: 0,
                 idcategoria : 0,
                 idgenero : 0,
                 iddiseno : 0,
                 idtela : 0,
                 idcolor : 0,
                 idtalla : 0,
                 idlote : 0,
                 idlugar : 0,
                 img : null,
                 url:'img/productos/',
                nombre_categoria : '',
                codigo : '',
                nombre : '',
                lugar : '',
                precio_venta : 0,
                precio_venta2 : 0,
                precio_costo : 0,
                descripcion : '',
                minimo : 0,
                arrayProducto : [],
                arrayCategoria :[],
                arrayGenero :[],
                arrayDiseno :[],
                arrayTela :[],
                arrayColor :[],
                arrayTalla :[],
                arrayLote :[],
                arrayLugar :[],
                modal : 0,
                modal2 : 0,
                urlhttp: this.$api_url,
                deshabilitar_boton:0,
                tituloModal : '',
                tituloModal2 : '',
                tipoAccion : 0,
                errorProducto : 0,
                errorMostrarMsjProducto : [],
                'mensaje':'',
                'val1':0,
                    'val2':0,
                    'val3':0,
                    'val4':0,
                    'val5':0,
                    'val6':0,
                    'val7':0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,

                },
                offset : 3,
                criterio : 'codigo',
                buscar : ''
            }
        },




        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;

            },


        },
        methods : {
            listarProducto (page,buscar,criterio){

                let me=this;
                 var url= '/producto?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio;
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos.data;
                    me.pagination= respuesta.pagination;

                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             GetImage(e){

                let image = e.target.files[0];
                this.img = image;
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.img = e.target.result
                }
            },



            cargarPdf(){

              window.open(this.urlhttp+'/producto/listarPdf','_blank');

            },


            scrollUp(){

                var currentScroll = document.documentElement.scrollTop;
                if(currentScroll > 0){
                   window.scrollTo(0,0);
                   //window.scrollTo(0,currentScroll - (currentScroll / 5));
                }

            },



              selectCategoria(){
                let me=this;
                var url= '/categoria/selectCategoria';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayCategoria = respuesta.categorias;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
/*
             selectGenero(){
                let me=this;
                var url= '/genero/selectGenero';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayGenero = respuesta.generos;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             selectDiseno(){
                let me=this;
                var url= '/diseno/selectDiseno';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayDiseno = respuesta.disenos;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             selectTela(){
                let me=this;
                var url= '/tela/selectTela';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayTela = respuesta.telas;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             selectColor(){
                let me=this;
                var url= '/color/selectColor';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayColor = respuesta.colors;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             selectTalla(){
                let me=this;
                var url= '/talla/selectTalla';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayTalla = respuesta.tallas;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             selectLote(){
                let me=this;
                var url= '/lote/selectLote';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLote = respuesta.lotes;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
*/
               cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarProducto(page,buscar,criterio);
            },


            registrarProducto(){
                if (this.validarProducto()){
                    return;
                }

                let lugari = this.idlugar;
               // console.log(lugari)



                  let me = this;
                 me.deshabilitar_boton=1;
                axios.post('/producto/registrar',{


                    'idcategoria': this.idcategoria,

                    //'idgenero': this.idgenero,
                    //'iddiseno': this.iddiseno,
                    //'idtela': this.idtela,
                    //'idcolor': this.idcolor,
                    //'idtalla': this.idtalla,
                    //'idlote': this.idlote,

                    'idlugar': this.idlugar,
                    'codigo': this.codigo,
                    'nombre': this.nombre,
                    'precio_costo': this.precio_costo,
                    'precio_venta': this.precio_venta,
                    'precio_venta2': this.precio_venta2,
                    'img' : this.img,

                    'descripcion': this.descripcion,
                    'minimo': this.minimo

                }).then(function (response) {

                    //console.log(response);

                    me.listarProducto(1,'','codigo');


                    me.cerrarModal();
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });


                }).catch(function (error) {
                    console.log(error);
                });


                this.scrollUp();

            },


            actualizarProducto(){


               if (this.validarProducto()){
                    return;
                }

              //console.log(this.img);

                let me = this;



                axios.put('/producto/actualizar',{



                    'idcategoria': this.idcategoria,

                    //'idgenero': this.idgenero,
                    //'iddiseno': this.iddiseno,
                    //'idtela': this.idtela,
                    //'idcolor': this.idcolor,
                    //'idtalla': this.idtalla,
                    //'idlote': this.idlote,

                    'codigo': this.codigo,
                    'nombre': this.nombre,
                    'precio_costo': this.precio_costo,
                    'precio_venta': this.precio_venta,
                    'precio_venta2': this.precio_venta2,
                    'descripcion': this.descripcion,
                    'minimo': this.minimo,
                    'img' : this.img,

                    'id': this.producto_id


                }).then(function (response) {
                 // console.log(response);
                    me.cerrarModal();
                    me.listarProducto(1,'','codigo');

                        Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });

                }).catch(function (error) {
                    console.log(error);
                });

                this.scrollUp();

            },


            duplicado(){

               axios.put('/producto/validar',{

                    'codigo': this.codigo,
                    'nombre': this.nombre,


                }).then(response => {
                  this.mensaje = response.data;
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },





             validarProducto(){


               this.errorProducto=0;
                this.errorMostrarMsjProducto  =[];


                if (this.idcategoria==0){ this.errorMostrarMsjProducto.push("Seleccione una Categoria."); this.val1=1; }
               /*
               if (this.idgenero==0){ this.errorMostrarMsjProducto.push("Seleccione un Genero."); this.val1=1; }
                if (this.iddiseno==0){ this.errorMostrarMsjProducto.push("Seleccione un Diseño."); this.val1=1; }
                if (this.idtela==0){ this.errorMostrarMsjProducto.push("Seleccione una Tela."); this.val1=1; }
                if (this.idcolor==0){ this.errorMostrarMsjProducto.push("Seleccione un color."); this.val1=1; }
                if (this.idtalla==0){ this.errorMostrarMsjProducto.push("Seleccione una Talla."); this.val1=1; }
                if (this.idlote==0){ this.errorMostrarMsjProducto.push("Seleccione un Lote."); this.val1=1; }
                */
                if (!this.codigo){ this.errorMostrarMsjProducto.push("El codigo del artículo no puede estar vacío."); this.val1=2; }
                if (!this.nombre){ this.errorMostrarMsjProducto.push("El nombre del artículo no puede estar vacío."); this.val1=1; }
                if (!this.precio_costo){ this.errorMostrarMsjProducto.push("El costo del artículo debe ser un número y no puede estar vacío."); this.val4=1; }
                if (!this.precio_venta){ this.errorMostrarMsjProducto.push("El precio venta del artículo debe ser un número y no puede estar vacío."); this.val3=1; }
                 if (!this.precio_venta2){ this.errorMostrarMsjProducto.push("El precio venta 2 del artículo debe ser un número y no puede estar vacío."); this.val3=1; }
                if (!this.minimo){ this.errorMostrarMsjProducto.push("El Minimo del artículo debe ser un número y no puede estar vacío."); this.val6=1; }



                if (this.errorMostrarMsjProducto.length) this.errorProducto = 1;

                return this.errorProducto;

            },
            cerrarModal(){
                this.modal=0;
                this.modal2=0;
                this.tituloModal='';
                this.idcategoria= 0;
                /*
                this.idgenero= 0;
                this.iddiseno= 0;
                this.idtela= 0;
                this.idcolor= 0;
                this.idtalla= 0;
                this.idlote= 0;
                */
                this.nombre_categoria = '';
                /*
                this.nombre_genero = '';
                this.nombre_diseno = '';
                this.nombre_tela = '';
                this.nombre_color = '';
                this.nombre_talla = '';
                this.nombre_lote = '';
                */

                this.codigo = '';
                this.nombre = '';
                this.precio_costo = 0;
                this.precio_venta = 0;
                this.precio_venta2 = 0;
                this.descripcion = '';
                this.img='';
                this.minimo = '';

                this.deshabilitar_boton=0;

                this.errorProducto=0;
                this.val1=0;
                this.val2=0;
                this.val3=0;
                this.val4=0;

            },
            abrirModal(modelo, accion, data = []){



                switch(modelo){
                    case "producto":
                    {
                        switch(accion){
                            case 'registrar':
                            {
                                this.scrollUp();
                               this.modal = 1;
                                this.tituloModal = 'Registrar Producto';
                                this.idcategoria= 0;
                                /*
                                this.idgenero= 0;
                                this.iddiseno= 0;
                                this.idtela= 0;
                                this.idcolor= 0;
                                this.idtalla= 0;
                                this.idlote= 0;
                                */
                                this.idlugar= 0;
                                this.nombre_categoria = '';
                                /*
                                this.nombre_genero = '';
                                this.nombre_diseno = '';
                                this.nombre_tela = '';
                                this.nombre_color = '';
                                this.nombre_talla = '';
                                this.nombre_lote = '';
                                */

                                this.codigo = '';
                                this.nombre = '';
                                this.lugar = '';
                                this.precio_costo = 0;
                                this.precio_venta = 0;
                                this.precio_venta2 = 0;
                                this.descripcion = '';
                                this.minimo = '';
                                this.tipoAccion = 1;
                                break;
                            }
                            case 'actualizar':
                            {
                                this.scrollUp();
                                //console.log(data);
                                this.modal=1;
                                this.tipoAccion=2;
                                this.tituloModal='Actualizar Producto';
                                this.producto_id=data['id'];
                                this.idcategoria=data['idcategoria'];
                                /*
                                this.idgenero=data['idgenero'];
                                this.iddiseno=data['iddiseno'];
                                this.idtela=data['idtela'];
                                this.idcolor=data['idcolor'];
                                this.idtalla=data['idtalla'];
                                this.idlote=data['idlote'];
                                */
                                this.codigo = data['codigo'];
                                this.nombre = data['nombre'];
                                this.precio_costo=data['precio_costo'];
                                this.precio_venta=data['precio_venta'];
                                this.precio_venta2=data['precio_venta2'];
                                this.descripcion= data['descripcion'];
                                this.minimo= data['minimo'];
                                this.img= data['img'];

                                break;
                            }


                        }
                    }
                }

                this.selectCategoria();
                /*
                 this.selectGenero();
                  this.selectDiseno();
                   this.selectTela();
                    this.selectColor();
                     this.selectTalla();
                      this.selectLote();
                      */



            },




           desactivarProducto : function(id){
              Swal.fire({
                title: 'Esta seguro/a que desea desactivar el Producto?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/producto/desactivar',{
                          'id': id
                        }).then(function(response){
                          me.listarProducto(1,'','codigo');

                          Swal.fire(
                            'Desactivado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })
                },


               activarProducto: function(id){

                Swal.fire({
                title: 'Esta seguro/a que desea activar Producto?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/producto/activar',{
                          'id': id
                        }).then(function(response){
                         me.listarProducto(1,'','codigo');

                          Swal.fire(
                            'activado!',
                            'El registro a sido activado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })


              }


        },
        mounted() {
            this.listarProducto(1,this.buscar,this.criterio);


        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>
