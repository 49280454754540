<template>



    <!-- Main content -->    
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Top Productos Mas Comprados</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">
       
      <div class="row">
        
<div class="col-12">
            

            <div class="card ">
              <div class="card-header">
                
               
                 <div class="form-group row">
                   <div class="col-md-12">
                     <div class="form-inline">

                       <label for="FormControlSelect1">Tienda: </label>
                       <select class="form-control" id="FormControlSelect1" v-model="lugar">
                        <option value="0" disabled>Seleccione</option>
                        <option v-for="lugar in arrayLugar" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                       </select>
                       
                    
                       <label for="FormControlSelect2">Top: </label>
                       <select name="" id="FormControlSelect2" class="form-control col-md-3" v-model="top">
                         <option value="5">5</option>
                         <option value="10">10</option>
                         <option value="15">15</option>
                         <option value="20">20</option>
                         <option value="50">50</option>
                         <option value="100">100</option>
                       </select>&nbsp;
                      

                     
                    
                        <label for="FormControlinput1">De: </label>
                       <input id="FormControlinput1" type="date" class="form-control" v-model="de" >&nbsp;
                      
                   
                    
                        <label for="FormControlinput2">A: </label>
                       <input id="FormControlinput2" type="date" class="form-control" v-model="a" >&nbsp;
                       
                        <button type="submit" class="btn btn-primary" @click.prevent="listarTop(lugar,de,a,top)"><i class="fa fa-search"></i>&nbsp;Buscar</button>
                     </div>
                      
                      <div v-show="errorReporte" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjReporte" :key="error" v-text="error">

                                        </div>
                                    </div>
                  </div>
                   </div>
                 </div>

               </div>
                
                
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Codigo</th>
                    <th>Nombre</th>
                    <th>Cantidad Comprada</th>
                   
                    
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(reporte,index) in arrayReporte" :key="reporte.id" >
                                    <td>{{(index + 1)}}</td>
                                    <td v-text="reporte.codigo"></td>
                                    <td v-text="reporte.nombre"></td>
                                    <td v-text="reporte.total"></td>
                                  
                 
                  </tr>
                 
                  
                  </tbody>
                  <tfoot>
                  
                  </tfoot>
                </table>
              
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>

    


 
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  
  <!-- /.content -->
</template>
<script>




export default {
  
       data (){
            return {
              
                lugar:0,
                de:"",
                a:"",
                top:5,
                arrayReporte : [],
                arrayRol : [],
                arrayLugar : [],
                modal : 0,
                errorReporte : 0,
                errorMostrarMsjReporte : [],
                'mensaje':'',
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                    
                },

                offset: 3,
            criterio: "fecha_hora",
            buscar: ""
                
            }
        },
        

  

        computed:{

       
       isActived: function() {
            return this.pagination.current_page;
        },
        //Calcula los elementos de la paginación
        pagesNumber: function() {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }

            var to = from + this.offset * 2;
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },

         
        },
        methods : { 



      

             selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },


            listarTop(lugar,de,a,top){



              if (this.validarReporte()){
                    return;
                }
            
                let me=this;
                 var url= '/productomascreporte?lugar=' + lugar + '&de='+ de + '&a='+ a + '&top='+ top  ;
                  axios.get(url).then(function (response) {
                  //console.log(response);
                  
                    var respuesta= response.data;
                    me.arrayReporte = respuesta.reporte;

                    
                    
                    
                  
                })
                .catch(function (error) {
                    console.log(error);
                });

                
            },


            


          

             validarReporte(){


             
                this.errorReporte=0;
                this.errorMostrarMsjReporte  =[];

                if (this.de==""){
                  this.errorMostrarMsjReporte.push("EL Rangos de Fecha esta Vacio.");
                  
                }

                if (this.a==""){
                   this.errorMostrarMsjReporte.push("Uno de los Rangos de Fecha esta Vacio.");
                  
                } 
                
                if (this.lugar==0){
                  this.errorMostrarMsjReporte.push("Seleccione un Lugar.");
                  
                }
                
                

                if (this.errorMostrarMsjReporte.length) this.errorReporte = 1;
                    return this.errorReporte;
               
             
            },


           
        },
        mounted() {
            
             this.selectLugar();
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>