import { render, staticRenderFns } from "./Comisiones.vue?vue&type=template&id=ac31bc7e&"
import script from "./Comisiones.vue?vue&type=script&lang=js&"
export * from "./Comisiones.vue?vue&type=script&lang=js&"
import style0 from "./Comisiones.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports