<template>
    <!-- Main content -->    
 <div class="content">

   <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Rol</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <div class="container-fluid">
       
      <div class="row">
        


<div class="col-12">
            

            <div class="card">
              <div class="card-header">
               
                 <div class="form-group row">
                   <div class="col-md-6">
                     <div class="input-group">
                       <select name="" id="" class="form-control col-md-3" v-model="criterio">
                         <option value="nombre">Nombre</option>
                         <option value="descripcion">Descripcion</option>
                       </select>
                       <input type="text" class="form-control" placeholder="Buscar" v-model="buscar" @keyup.enter="listarDatos(1,buscar,criterio)">
                       <button type="submit" class="btn btn-primary" @click.prevent="listarDatos(1,buscar,criterio)"><i class="fas fa-search"></i>&nbsp;Buscar</button>
                     </div>
                   </div>
                 </div>
               </div>
              </div>



              <!-- /.card-header -->
              <div class="card-body table-responsive-sm" >
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Estado</th>
                    
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="rol in arrayDatos" :key="rol.id" >
                   
                     <td v-text="rol.nombre"></td>
                   <td v-text="rol.descripcion"></td>
                   <td>
                       <div v-if="rol.condicion">
                            <span class="badge badge-success"> Activo </span>
                       </div>
                       <div v-else>
                            <span class="badge badge-danger"> Desactivado </span>
                       </div>
                      
                   </td>
                 
                  </tr>
                 
                  
                  </tbody>
                  <tfoot>
                  
                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>

 
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  
  <!-- /.content -->
</template>

<script>
    export default {
        data (){
            return {
                rol_id: 0,
                nombre : '',
                descripcion : '',
                arrayDatos : [],
                modal : 0,
                tituloModal : '',
                tipoAccion : 0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset : 3,
                criterio : 'nombre',
                buscar : ''
            }
        },
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }
                
                var from = this.pagination.current_page - this.offset; 
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2); 
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }  

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;             

            }
        },
        methods : {
            listarDatos (page,buscar,criterio){
                let me=this;
                var url= '/rol?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio;
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayDatos = respuesta.roles.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarDatos(page,buscar,criterio);
            }
        },
        mounted() {
            this.listarDatos(1,this.buscar,this.criterio);
        }
    }
</script>
<style>    
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
    }
    .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }
</style>