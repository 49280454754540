<template>
            <main class="main">
            <!-- Content Header (Page header) -->
            <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">

                    <h1 class="m-0 text-dark">Ventas Pos</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">

                </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
            </div>
            <div class="container-fluid">
                <!-- Ejemplo de tabla Listado -->
                <div class="card">


                    <div class="card-header">
                        <i class="fa fa-align-justify"></i> Ventas Pos
                        <button type="button" @click.prevent="mostrarDetalle()" class="btn btn-success">
                            <i class="fas fa-plus-square"></i>&nbsp;Nuevo
                        </button>

                        <!--
                        <button type="button" @click.prevent="validarUser()" class="btn btn-success">
                            <i class="fas fa-plus-square"></i>&nbsp;Validar
                        </button>
                       -->
                    </div>
                    <!------------------------------------------------- Listado Principal ------------------------------>
                    <template v-if="listado==1">
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tienda</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar2">
                                    <option value="0" disabled>Seleccione</option>
                                            <option v-for="lugar in arrayLugar2" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                                    </select>
                                 </div>

                                <div class="input-group">

                                    <select class="form-control col-md-3" v-model="criterio">
                                      <option value="tipo_comprobante">Tipo Comprobante</option>
                                      <option value="num_comprobante">Número Comprobante</option>
                                      <option value="fecha_hora">Fecha-Hora</option>
                                      <option value="nombre">Cliente</option>
                                    </select>
                                    <input type="text" v-model="buscar" @keyup.enter="listarVentap(1,buscar,criterio,idlugar2)" class="form-control" placeholder="Texto a buscar">
                                    <button type="submit" @click="listarVentap(1,buscar,criterio,idlugar2)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                </div>
                            </div>
                        </div>
                        <h5>Total Venta: Q {{calcularTotalv}}</h5>
                                <h6>Cantidad:  {{contarVenta}}</h6>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Opciones</th>
                                        <th>Usuario</th>
                                        <th>Cliente</th>
                                        <th>Tipo Comprobante</th>
                                        <th>Número Comprobante</th>
                                        <th>Fecha Hora</th>
                                        <th>Total</th>

                                        <th>TotalCosto</th>
                                        <th>Utilidad</th>

                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ventap,index) in arrayVentap" :key="ventap.id">
                                        <td>
                                            {{(index+1)}}
                                        </td>
                                        <td>
                                            <button type="button" @click.prevent="verVentap(ventap.id)" class="btn btn-success btn-sm">
                                            <i class="fas fa-eye"></i>
                                            </button> &nbsp;
                                            <button type="button" @click.prevent="pdfVentap(ventap.id)" class="btn btn-info btn-sm">
                                            <i class="fas fa-file-pdf"></i>
                                            </button> &nbsp;
                                            <template v-if="ventap.estado=='Registrado'">
                                                <button type="button" class="btn btn-danger btn-sm" @click.prevent="desactivarVentap(ventap.id,ventap.num_comprobante,ventap.total)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </template>
                                            <!--
                                            <template v-if="ventap.estado=='Anulado'">
                                                <button type="button" class="btn btn-danger btn-sm" @click.prevent="VerAnulacion(ventap.id,ventap.num_comprobante,ventap.total)">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </template>
                                            -->
                                        </td>
                                        <td v-text="ventap.usuario"></td>
                                        <td v-text="ventap.nombre"></td>
                                        <td v-text="ventap.tipo_comprobante"></td>
                                        <td v-text="ventap.num_comprobante"></td>
                                        <td v-text="ventap.fecha_hora"></td>
                                        <td v-text="ventap.total"></td>

                                        <td v-text="ventap.totalc"></td>
                                        <td v-text="ventap.totalu"></td>

                                        <td>
                                            <div v-if="ventap.estado=='Registrado'">
                                              <span class="badge badge-success"> Registrada</span>
                                             </div>

                                            <div v-if="ventap.estado=='Anulado'">
                                                <span class="badge badge-danger"> Anulada</span>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav>
                            <ul class="pagination">
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio,idlugar2)">Ant</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio,idlugar2)" v-text="page"></a>
                                </li>
                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio,idlugar2)">Sig</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    </template>
                    <!--Fin Listado-->

                <!------------------------------------------------- Detalle ------------------------------>
                    <template v-else-if="listado==0">
                    <div class="card-body">
                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Cliente <span style="color:red;" v-show="idcliente==0">(*Seleccione)</span></label>
                                    <div class="form-inline">
                                        <button @click.prevent="abrirModalC()" class="btn btn-success"><i class="fas fa-plus"></i></button>
                                        <button @click.prevent="abrirModalB()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                         <input type="text"  class="form-control" v-on:keyup.enter="buscarBcliente()" v-model="bcliente">
                                       <div>
                                           <input type="text"   readonly class="form-control limpiar" v-model="nit">
                                        <input type="text"   readonly class="form-control limpiar" v-model="nombrep">
                                        <input type="text"   readonly class="form-control limpiar" v-model="direccion">
                                        <input type="text"   readonly class="form-control limpiar" v-model="telefono">

                                       </div>

                                    </div>
                              </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo Comprobante(*)</label>
                                    <select class="form-control" v-model="tipo_comprobante">
                                        <option value="0">Seleccione</option>

                                        <option value="POS">pos</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Número Comprobante(*)</label>
                                    <input type="text"  class="form-control" v-model="num_comprobante" placeholder="000xx">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div v-show="errorVentap" class="form-group row div-error">
                                    <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjVentap" :key="error" v-text="error">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="form-group row border">
                             <div class="col-md-9">
                                 <div class="form-group">
                                       <label for="exampleFormControlSelect1">Tienda <span style="color:red;" v-show="idlugar==[]">(*Seleccione)</span></label>
                                       <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar">
                                         <option value="0" disabled>Seleccione</option>
                                          <option v-for="lugar in arrayLugar" :key="lugar.id" :value="[lugar.id,lugar.nombre,lugar.direccion]" v-text="lugar.nombre"></option>
                                        </select>
                                     </div>
                             </div>
                        </div>
                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Producto <span style="color:red;" v-show="idproducto==0">(*Seleccione)</span></label>
                                    <div class="form-inline">
                                        <button @click.prevent="abrirModal()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                         <input  v-on:keyup.enter="buscarProducto2()" v-model="codigo" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="col-md-4">
                                <div class="form-group">
                                    <label>Total Articulos</label>
                                    <p>{{totalArticulo}}</p>
                                </div>
                            </div>

                        <div class="form-group row border">
                            <div class="table-responsive-md col-md-12">
                                <table  class="table table-bordered table-striped ">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Opciones</th>
                                            <th>Codigo</th>
                                            <th>Producto</th>
                                            <th>Costo</th>
                                            <th>Precio</th>
                                             <th>Precio2</th>


                                            <th>Garantia</th>
                                            <th>Cantidad</th>
                                            <!--
                                            <th>Descuento</th>
                                            -->
                                            <th>Subtotal</th>
                                            <th>SubtotalCosto</th>
                                            <th>SubtotalUtilidad</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                            <td>
                                                {{(index+1)}}
                                            </td>
                                            <td>
                                                <button @click="eliminarDetalle(index)" type="button" class="btn btn-danger btn-sm">
                                                    <i class="fas fa-times-circle"></i>
                                                </button>
                                            </td>
                                            <td v-text="detalle.codigo">
                                            </td>
                                            <td v-text="detalle.producto">
                                            </td>
                                             <td>
                                                <input style="width: 100px" disabled v-model="detalle.costo" type="number" value="4" class="form-control" >
                                            </td>
                                            <td>

                                                <input style="width: 100px" v-model="detalle.precio" type="number" value="3" class="form-control" >
                                                  <template v-if="detalle.precio < detalle.precio2">
                                                     <span style="color:red;" >El precio es menor al Precio2!</span>
                                                </template>
                                            </td>
                                            <td>

                                                <input disabled style="width: 100px" v-model="detalle.precio2" type="number" value="3" class="form-control" >
                                            </td>

                                            <td>

                                                <input style="width: 100px" v-model="detalle.garantia" type="text"  class="form-control" >
                                            </td>
                                            <td>
                                                 <span style="color:red;" v-show="(detalle.stock < detalle.cantidad)">Cantidad Mayor al Stock</span>
                                                <input v-model.number="detalle.cantidad"  type="number" value="2" class="form-control">
                                            </td>
                                            <!--
                                            <td>
                                                <span style="color:red;" v-show="detalle.descuento>(detalle.precio*detalle.cantidad)">Descuento superior</span>
                                                <input v-model="detalle.descuento" type="number" class="form-control">
                                            </td>
                                            -->
                                            <td>
                                                {{(detalle.precio*detalle.cantidad-detalle.descuento).toFixed(2)}}
                                            </td>
                                             <td>
                                                {{(detalle.costo*detalle.cantidad).toFixed(2)}}
                                            </td>
                                             <td>
                                               {{(((detalle.precio*detalle.cantidad-detalle.descuento).toFixed(2))-((detalle.costo*detalle.cantidad).toFixed(2))).toFixed(2)}}
                                            </td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total :</strong></td>
                                            <td>Q {{totalParcial=calcularTotal}}</td>
                                        </tr>
                                        <!--
                                          <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Servicio Pos:</strong></td>
                                            <td> 5%</td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{total=calcularTotalPos}}</td>
                                        </tr>
                                        -->
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>TotalUtilidad:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>
                                    </tbody>




                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <!--  ---------  Tabla cotizar ----------->

                                 <table id="producto"  v-show="cotizar" class="table table-bordered table-striped ">


                                    <thead>
                                        <tr>
                                           <th>No</th>
                                            <th>Producto</th>
                                            <th>Costo</th>
                                            <th>Precio</th>
                                            <th>Cantidad</th>
                                            <th>Descuento</th>
                                            <th>Subtotal</th>
                                            <th>SubtotalCosto</th>
                                            <th>SubtotalUtilidad</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                            <td>{{(index+1)}}</td>
                                            <td v-text="detalle.producto"></td>
                                            <td v-text="detalle.costo"></td>
                                            <td v-text="detalle.precio"></td>
                                            <td v-text="detalle.cantidad"></td>
                                            <td v-text="detalle.descuento"></td>
                                            <td>
                                               Q {{detalle.precio*detalle.cantidad-detalle.descuento}}
                                            </td>
                                            <td>
                                               Q {{detalle.costo*detalle.cantidad}}
                                            </td>
                                              <td>
                                               Q {{(((detalle.precio*detalle.cantidad-detalle.descuento).toFixed(2))-((detalle.costo*detalle.cantidad).toFixed(2))).toFixed(2)}}
                                            </td>
                                        </tr>



                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total:</strong></td>
                                            <td>Q {{total=calcularTotal}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalUtilidad:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>
                                    </tbody>



                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">


                                     <button type="button"  class="btn btn-primary" :disabled = "deshabilitar_boton==1"  @click="registrarVentap()">Registrar Venta</button>

                                     <button type="button" @click="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>


                            </div>
                        </div>
                    </div>
                    </template>
                    <!-- Fin Detalle-->

                    <!------------------------------------------------- Ver Ventap_detalle ------------------------------>
                    <template v-else-if="listado==2">
                    <div class="card-body">
                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="">Cliente</label>
                                    <p v-text="cliente"></p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo Comprobante</label>
                                    <p v-text="tipo_comprobante"></p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Número Comprobante</label>
                                    <p id="numVentap" v-text="num_comprobante"></p>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Total Articulos</label>
                                    <p>{{totalArticulo}}</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Estado</label>
                                    <p id="estado" v-text="estado"></p>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row border">
                            <div class="table-responsive col-md-12">

                                <table class="table table-bordered table-striped table-sm">

                                    <thead>
                                        <tr>



                                            <th>No</th>

                                            <th>Codigo</th>

                                            <th>Categoria</th>

                                            <th>Producto</th>
                                            <th>Costo</th>
                                            <th>Precio</th>
                                            <th>Garantia</th>
                                            <th>Cantidad</th>
                                            <!--
                                            <th>Descuento</th>
                                            -->
                                            <th>Subtotal</th>
                                            <th>SubtotalCosto</th>
                                            <th>SubtotalUlitidad</th>


                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                            <td>
                                                {{(index+1)}}
                                            </td>


                                            <td v-text="detalle.codigo">
                                            </td>

                                           <td v-text="detalle.categoria">
                                            </td>

                                            <td v-text="detalle.articulo">
                                            </td>

                                            <td v-text="detalle.costo">
                                            </td>
                                            <td v-text="detalle.precio">
                                            </td>
                                            <td v-text="detalle.garantia">
                                            </td>
                                            <td v-text="detalle.cantidad">
                                            </td>
                                            <!--
                                            <td v-text="detalle.descuento">
                                            </td>
                                            -->
                                             <td>
                                                {{(detalle.precio*detalle.cantidad-detalle.descuento).toFixed(2)}}
                                            </td>
                                            <td>
                                                {{(detalle.costo*detalle.cantidad).toFixed(2)}}
                                            </td>
                                            <td>
                                                 {{(((detalle.precio*detalle.cantidad-detalle.descuento).toFixed(2))-((detalle.costo*detalle.cantidad).toFixed(2))).toFixed(2)}}
                                            </td>
                                        </tr>


                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Parcial:</strong></td>
                                            <td>Q {{totalParcial=calcularTotal}}</td>
                                        </tr>
                                        <!--
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Impuesto:</strong></td>
                                            <td>Q {{totalImpuesto=((total*impuesto)).toFixed(2)}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Servicio Pos:</strong></td>
                                            <td> 5%</td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Neto:</strong></td>
                                           <td >Q <span id="totalVentaArticulo">{{calcularTotalPos}}</span> </td>
                                        </tr>
                                    -->

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalUtilidad:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>



                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="4">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <button type="button" @click="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>

                            </div>
                        </div>
                    </div>
                    </template>
                    <!-- fin ver ventap -->
                </div>
                <!-- Fin ejemplo de tabla Listado -->
            </div>

   <!------------------------------------------------- Modal agregar ------------------------------>

            <div class="modal fade p-5" tabindex="-1" :class="{'mostrar' : modal}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-xl modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModal"></h4>
                            <button type="button" class="close" @click.prevent="cerrarModal()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">

                                    <div class="input-group">

                                        <select class="form-control col-md-3" v-model="criterioA">
                                        <option value="codigo">Codigo</option>
                                        <option value="nombre">Nombre</option>
                                        <option value="descripcion">Descripción</option>
                                        <option value="stock">Stock</option>
                                        </select>
                                        <input type="text" v-model="buscarA" @keyup.enter="listarProducto(1,buscarA,criterioA,idlugar)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click="listarProducto(1,buscarA,criterioA,idlugar)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Imagen</th>
                                            <th>Categoria</th>
                                            <th>Codigo</th>
                                            <th>Nombre</th>

                                            <th>Descripcion</th>


                                            <th>Precio Ventap</th>
                                             <th>Precio Ventap_2</th>
                                            <th>Precio Costo</th>
                                            <th>Stock</th>
                                            <th>Estado</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="producto in arrayProducto" :key="producto.id">
                                            <td>
                                                <button type="button" @click.prevent="agregarDetalleModal(producto)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                            </td>
                                             <td>
                                            <div class="contentimg">
                                                <img :src="url+producto.img" class="imgcrud" alt="">
                                            </div>
                                            </td>
                                           <td v-text="producto.nombre_categoria"></td>
                                           <td v-text="producto.codigo"></td>
                                            <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.nombre"></td>
                                            <td v-else v-text="producto.nombre"></td>

                                             <td v-text="producto.descripcion"></td>

                                            <td v-text="producto.precio_venta"></td>
                                             <td v-text="producto.precio_venta2"></td>
                                            <td v-text="producto.precio_costo"></td>
                                            <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                             <td v-else v-text="producto.stock"></td>
                                            <td>
                                                <div v-if="producto.condicion">
                                                    <span class="badge badge-success">Activo</span>
                                                </div>
                                                <div v-else>
                                                    <span class="badge badge-danger">Desactivado</span>
                                                </div>

                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <nav>
                                <ul class="pagination pagination-sm m-0 float-right">
                                <li class="page-item" v-if="pagination2.current_page > 1">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page - 1,buscarA,criterioA,idlugar)" >&laquo;</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber2" :key="page" :class="[page == isActived2 ? 'active' : '']">
                                    <a class="page-link" href="#" @click="cambiarPagina2(page,buscarA,criterioA,idlugar)" v-text="page"></a>
                                    </li>
                                <li class="page-item" v-if="pagination2.current_page < pagination2.last_page">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page + 1,buscarA,criterioA,idlugar)">&raquo;</a>
                                    </li>
                                </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarModal()">Cerrar</button>

                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->




            <div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal2}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal2"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">

                   <div class="card-body table-responsive">
                <table  class="table table-bordered table-striped">
                  <thead>
                  <tr>


                    <th>Nombre</th>
                    <th>Stock</th>
                    <th>Descripcion</th>
                    <th>Minimo</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="producto in arrayProducto" :key="producto.id" >


                                    <td v-if="producto.stock <= producto.minimo"  v-text="producto.nombre"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                    <td v-if="producto.stock <= producto.minimo" v-text="producto.descripcion"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.minimo"></td>




                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio,idlugar2)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio,idlugar2)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio,idlugar2)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>

            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




             <!------------------------------------------------- Modal Cliente ------------------------------>

            <div class="modal fade p-5" tabindex="-1" :class="{'mostrar' : modalB}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModalB"></h4>
                            <button type="button" class="close" @click="cerrarModalB()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <select class="form-control col-md-3" v-model="criterioB">
                                        <option value="nit">Nit</option>
                                        <option value="nombre">Nombre</option>

                                        </select>
                                        <input type="text" v-model="buscarB" @keyup.enter="listarCliente(buscarB,criterioB)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click="listarCliente(buscarB,criterioB)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table  class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Nit</th>
                                            <th>Nombre</th>
                                            <th>Direccion</th>
                                            <th>Telefono</th>
                                             <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cliente in arrayCliente" :key="cliente.id">
                                            <td>
                                                <button type="button" @click.prevent="agregarClienteModal(cliente)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                            </td>

                                            <td v-text="cliente.nit"></td>
                                            <td v-text="cliente.nombre"></td>
                                            <td v-text="cliente.direccion"></td>
                                            <td v-text="cliente.telefono"></td>
                                            <td>
                                                <div v-if="cliente.condicion">
                                                    <span class="badge badge-success">Activo</span>
                                                </div>
                                                <div v-else>
                                                    <span class="badge badge-danger">Desactivado</span>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarModalB()">Cerrar</button>
                            <button type="button" v-if="tipoAccion==1" class="btn btn-primary" @click.prevent="registrarCliente()">Guardar</button>
                            <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click.prevent="actualizarCliente()">Actualizar</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->

             <div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modalC}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModalC"></h4>
              <button type="button" class="close" @click.prevent="cerrarModalC()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Nit:</label>
                     <input type="text" class="form-control" @blur="duplicado()"    v-model="nit">
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Nombre:</label>
                      <input type="text" class="form-control"  v-model="nombre" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Direccion:</label>
                      <input type="text" class="form-control"  v-model="direccion" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Telefono:</label>
                      <input type="text" class="form-control"  v-model="telefono" >
                  </div>


                   <div v-show="errorCliente" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjCliente" :key="error" v-text="error">

                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row  div-error">
                    <p class="text-center text-error">El Nit ya Existe!</p>
                  </div>
                  </form>
                </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click.prevent="cerrarModalC()">Cerrar</button>
              <button type="button" :disabled = "deshabilitar_boton==1" class="btn btn-primary" @click="registrarCliente()">Guardar</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->
        </main>
</template>

<script>


    export default {
        data (){
            return {
                ventap_id: 0,
                idcliente:0,
                idlugar:[],
                idlugar2:1,
                nit: '',
                lugar:'',
                url:'img/productos/',
                cotizar:0,
                cliente:'',
                codigo:'',
                nombre : '',
                nombrep : '',
                bcliente : '',
                direccion : '',
                telefono : '',
                tipo_comprobante : 'POS',
                serie_comprobante : '',
                num_comprobante : '',
                estado : '',
                deshabilitar_boton:0,
                urlhttp: this.$api_url,
                impuesto: 0.0,
                total:0.0,
                totalc:0.0,
                totalu:0.0,
                recibido:0.0,
                totalImpuesto: 0.0,
                totalParcial: 0.0,
                arrayVentap : [],
                arrayCliente: [],
                arrayDetalle : [],
                arrayLugar : [],
                 arrayLugar2 : [],
                 arrayAnularV: [],
                 arrayAnulacion: [],
                razonAnulacion : '',
                 'mensaje':'',
                listado:1,
                modal : 0,
                modalB : 0,
                modalC : 0,
                 modal2 : 0,
                tituloModal : '',
                tituloModalB : '',
                tituloModalC : '',
                 tituloModal2 : '',
                tipoAccion : 0,
                errorVentap : 0,
                errorCliente : 0,
                errorMostrarMsjVentap : [],
                errorMostrarMsjCliente : [],
                 arrayBuscarCliente: [],

                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset : 3,

                 pagination2 : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset2 : 3,
                criterio : 'fecha_hora',
                buscar : '',
                criterioA:'nombre',
                buscarA: '',
                criterioB:'nombre',
                buscarB: '',
                arrayProducto: [],
                idproducto: 0,
               // codigo: '',
                producto: '',
                precio: 0,
                cantidad:0,
                descuento: 0,
                cambio: 0,
                negativo: 0,
                stock: 0
            }
        },
        components: {

        },
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            isActived2: function(){
                return this.pagination2.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            },
            pagesNumber2: function() {
                if(!this.pagination2.to) {
                    return [];
                }

                var from = this.pagination2.current_page - this.offset2;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset2 * 2);
                if(to >= this.pagination2.last_page){
                    to = this.pagination2.last_page;
                }

                var pagesArray2 = [];
                while(from <= to) {
                    pagesArray2.push(from);
                    from++;
                }
                return pagesArray2;
            },



            calcularTotalv: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayVentap.length;i++){

                     if(this.arrayVentap[i].estado=="Registrado"){
                       resultado +=parseFloat(this.arrayVentap[i].total)
                     }

                }
                return resultado.toFixed(2);
            },
            contarVenta: function(){

               var resultado=0.0;
                for(var i=0;i<this.arrayVentap.length;i++){

                    resultado =i+1;

                }
                return resultado;
            },

            calcularTotal: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad-this.arrayDetalle[i].descuento)
                }
                return resultado.toFixed(2);
            },


            calcularTotalc: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },


            calcularTotalu: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+((this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad-this.arrayDetalle[i].descuento)-(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad))
                }
                return resultado.toFixed(2);
            },

                     calcularTotalPos: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad-this.arrayDetalle[i].descuento)
                }

               var porcentaje = (resultado *0.05);
               var total = (resultado+porcentaje);

                return total.toFixed(2);
            },


            totalArticulo: function(){
                var result=0;
                 for(var i=0;i<this.arrayDetalle.length;i++){

                    result+=(this.arrayDetalle[i].cantidad)
                }
                return result;


            },

            contarAlerta: function(){

              var resultado=0;
                for(var i=0;i<this.arrayProducto.length;i++){

                    if(this.arrayProducto[i].stock <= this.arrayProducto[i].minimo){

                      resultado +=1;
                    }


                }
                return resultado;
            }
        },
        methods : {


      /**************************************************************************************** */
            listarVentap (page,buscar,criterio,idlugar2){

                let me=this;
                var url= '/ventap?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio + '&idlugar2='+ idlugar2;
                axios.get(url).then(function (response) {

                    var respuesta= response.data;
                    me.arrayVentap = respuesta.ventasp.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

       /**************************************************************************************** */
            buscarCliente(){
                let me=this;


                var url= '/cliente/selectCliente?filtro='+search;
                axios.get(url).then(function (response) {
                    let respuesta = response.data;
                    me.arrayCliente=respuesta.clientees;

                })
                .catch(function (error) {
                    console.log(error);
                });
            },

        /**************************************************************************************** */
            getDatosCliente(val1){
                let me = this;
                me.loading = true;
                me.idcliente = val1.id;
            },

         /**************************************************************************************** */
            buscarProducto(){
                let me=this;
                var url= '/existencia/buscarProducto?filtro=' + me.nombre;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos;

                    if (me.arrayProducto.length>0){
                        me.producto=me.arrayProducto[0]['nombre'];
                        me.idproducto=me.arrayProducto[0]['id'];
                    }
                    else{
                        me.producto='No existe producto';
                        me.idproducto=0;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            buscarProducto2(){
                    let me=this;
                let idlugar = me.idlugar;
               var url= '/existencia/buscarProducto2?filtro=' + me.codigo + '&idlugar='+ idlugar[0];



                axios.get(url).then(function (response) {


                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos;





                    if (me.arrayProducto.length>0){
                        me.codigo=me.arrayProducto[0]['codigo'];
                        me.idproducto=me.arrayProducto[0]['id'];
                        me.nombre=me.arrayProducto[0]['nombre'];
                        me.costo=me.arrayProducto[0]['precio_costo'];
                        me.precio=me.arrayProducto[0]['precio_venta'];
                        me.precio2=me.arrayProducto[0]['precio_venta2'];
                        me.stock= me.arrayProducto[0]['stock'];

                 if(me.encuentra(me.arrayProducto[0]['id']) ){
                     /*
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                            */
                                 for(var i=0;i<me.arrayDetalle.length;i++){
                    if(me.arrayDetalle[i].idproducto==me.idproducto){
                        me.arrayDetalle[i].cantidad= me.arrayDetalle[i].cantidad + 1;
                    }
                }
                    }else{
                        me.arrayDetalle.push({
                            idproducto: me.arrayProducto[0]['id'],
                            codigo: me.arrayProducto[0]['codigo'],
                            producto: me.arrayProducto[0]['nombre'],
                            cantidad: 1,
                            costo: me.arrayProducto[0]['precio_costo'],
                            precio: me.arrayProducto[0]['precio_venta'],
                            precio2: me.arrayProducto[0]['precio_venta2'],
                            garantia:'s/n',
                            descuento: 0,
                            stock: me.arrayProducto[0]['stock'],
                        });
                    }


/*
                         if(me.arrayDetalle[i].idproducto==me.arrayProducto[0]['id']){

                             me.arrayDetalle.push({
                                 cantidad: cantidad+1
                                 });

                         }else{

                             me.arrayDetalle.push({
                            idproducto: me.arrayProducto[0]['id'],
                            codigo: me.arrayProducto[0]['codigo'],
                            producto: me.arrayProducto[0]['nombre'],
                            cantidad: 1,
                            costo: me.arrayProducto[0]['precio_costo'],
                            precio: me.arrayProducto[0]['precio_ventap'],
                            descuento: 0,
                            stock: me.arrayProducto[0]['stock'],
                        });

                         }

                    */


                        /*
                         me.arrayDetalle.push({
                            idproducto: data['id'],
                            producto: data['nombre'],
                            cantidad: 1,
                            precio: data['precio_ventap'],
                            costo: data['precio_costo'],
                            descuento:0,
                            stock:data['stock']

                        });
                        */
                       /*
                        console.log(me.idproducto);
                        console.log(me.codigo);
                       console.log(me.nombre);
                       console.log(me.costo);
                       console.log(me.precio);
                       console.log(me.stock);
                       */
                       me.codigo="";
                    }
                    else{

                        me.arrayProducto = [];

                         me.codigo = "";
                       swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'No Existe el Codigo de Producto!',
                            })


                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

            },


                    buscarBcliente(){
 let me=this;

              if(me.bcliente==""){

                  me.bcliente;
                        me.arrayCliente=[];

                  swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ingrese un Numero de Nit!',
                            })
                      me.arrayCliente=[];
                        me.idcliente="";
                       me.nit="";
                        me.nombrep="";
                        me.direccion="";
                        me.telefono="";

              }else{

                  var url= '/cliente/bcliente?filtro=' + me.bcliente;



                axios.get(url).then(function (response) {
                /*
                  var respuesta= response.data;
                    me.arrayProducto = respuesta.productos;
                    */
                    var respuesta= response.data;
                    me.arrayBuscarCliente = respuesta.buscaCliente;
                   /*
                      var respuesta= response.data;
                      me.arrayCliente = respuesta.cliente.data;
                   */



                    if(me.arrayBuscarCliente.length>0){



                       me.idcliente=me.arrayBuscarCliente[0]["id"];
                       me.nit=me.arrayBuscarCliente[0]["nit"];
                        me.nombrep=me.arrayBuscarCliente[0]["nombre"];
                        me.direccion=me.arrayBuscarCliente[0]["direccion"];
                        me.telefono=me.arrayBuscarCliente[0]["telefono"];


                       /*
                        me.nit=me.arrayBuscarCliente[1];
                        me.nombre=me.arrayBuscarCliente[2];
                        me.direccion=me.arrayBuscarCliente[3];
                        me.telefono=me.arrayBuscarCliente[4];
                        */


                    }

                    else{

                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'No existe el Nit!',
                            })

                        me.bcliente="";
                        me.arrayBuscarCliente=[];

                        me.idcliente="";
                       me.nit="";
                        me.nombrep="";
                        me.direccion="";
                        me.telefono="";



                    }



                })
                .catch(function (error) {
                    console.log(error);
                });

              }
        },



             selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            selectLugar2(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar2 = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },




          /**************************************************************************************** */

            cambiarPagina(page,buscar,criterio,idlugar2){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarVentap(page,buscar,criterio,idlugar2);
            },

             cambiarPagina2(page,buscarA,criterioA,idlugar){
                let me = this;
                //Actualiza la página actual
                me.pagination2.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarProducto(page,buscarA,criterioA,idlugar);
            },

          /**************************************************************************************** */
            encuentra(id){
                var sw=0;
                for(var i=0;i<this.arrayDetalle.length;i++){
                    if(this.arrayDetalle[i].idproducto==id){
                        sw=true;
                    }
                }
                return sw;
            },

          /**************************************************************************************** */
            eliminarDetalle(index){
                let me = this;
                me.arrayDetalle.splice(index, 1);
            },


           /**************************************************************************************** */
            agregarDetalle(){
                let me=this;
                if(me.idproducto==0 || me.cantidad==0 || me.precio==0){
                }
                else{
                    if(me.encuentra(me.idproducto)){
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: me.idproducto,
                            codigo: me.codigo,
                            producto: me.producto,
                            cantidad: me.cantidad,
                            precio: me.precio,

                            costo: me.costo,
                            descuento: me.descuento,
                            stock: me.stock
                        });
                        me.codigo="";
                        me.idproducto=0;
                        me.producto="";
                        me.cantidad=0;
                        me.precio=0;

                        me.costo=0;
                        me.descuento=0;
                        me.stock=0
                    }

                }



            },

             /**************************************************************************************** */
            agregarDetalleModal(data =[]){

                let me=this;
                if(me.encuentra(data['id'])){

                    /*
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                         */

                                  for(var i=0;i<me.arrayDetalle.length;i++){
                    if(me.arrayDetalle[i].idproducto==data['id']){
                        me.arrayDetalle[i].cantidad= me.arrayDetalle[i].cantidad + 1;
                    }
                }
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: data['id'],
                             codigo: data['codigo'],
                            producto: data['nombre'],
                            descripcion: data['descripcion'],
                            cantidad: 1,
                            precio: data['precio_venta'],
                            precio2: data['precio_venta2'],
                            garantia:'s/n',
                            costo: data['precio_costo'],
                            descuento:0,
                            stock:data['stock']

                        });
                    }
            },

            agregarDetalleModal2(data =[]){

                let me=this;
                if(me.encuentra(data['id'])){
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: data['id'],
                            codigo: data['codigo'],
                            producto: data['nombre'],
                            cantidad: 1,
                            precio: data['precio_venta'],
                            precio2: data['precio_venta2'],
                            garantia:'s/n',
                            costo: data['precio_costo'],
                            descuento:0,
                            stock:data['stock']

                        });
                    }
            },

            /**************************************************************************************** */
            agregarClienteModal(data =[]){

                this.bcliente = "";
                let me=this;


                                me.idcliente=data['id'];
                                me.nit = data['nit'];
                                me.nombrep = data['nombre'];
                                me.direccion = data['direccion'];
                                me.telefono = data['telefono'];
                this.cerrarModalB();
                this.bcliente = "";

            },


             registrarCliente(){
                if (this.validarCliente()){
                    return;
                }

                let me = this;
                 me.deshabilitar_boton=1;
                axios.post('/cliente/registrar',{


                    'nit': this.nit,
                    'nombre': this.nombre,
                    'direccion': this.direccion,
                    'telefono' : this.telefono

                }).then(function (response) {

                     me.bcliente ="";
                    me.bcliente = me.nit;

                    me.cerrarModalC();
                    me.deshabilitar_boton=0;

                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                }).catch(function (error) {
                    console.log(error);
                });
            },
             /**************************************************************************************** */
            listarProducto (page,buscarA,criterioA,idlugar){
                let me=this;
                var url= '/existencia/listarProducto?page=' + page + '&buscar='+ buscarA + '&criterio='+ criterioA + '&idlugar='+ idlugar[0];
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos.data;
                    me.pagination2= respuesta.pagination2;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },


            pdfVentap(id){

                 window.open('/ventap/pdf/'+id+','+'_blank');

            },


           cotizacion(){



                  if (this.validarCotizacion()){
                    return;
                }


                Swal.fire({
                    title: 'Cotizacion a Nombre de:',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    preConfirm: (login) => {

                        var f = new Date();
                        var fecha = (f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear());

                         this.cotizar==1;

               const doc = new jsPDF();
                        doc.setFontSize(12);
                        doc.text("Titulos", 20, 5);
                         doc.setFontSize(7);
                        doc.text("DIRECCION:", 20, 8);
                         doc.setFontSize(7);
                        doc.text("TEL:", 20, 12);



                        doc.setFontSize(10);
                        doc.text("Cotizacion a Nombre De: "+login,100,5);
                        doc.setFontSize(7);
                        doc.text("Fecha: "+fecha,185,5);
                        doc.addImage("img/mipueblitologo.png", "PNG", 5, 2, 10, 10);
                        doc.setFontSize(7);
                        doc.text("VIGENCIA DE COTIZACION Tiempo DESPUES DE SU EMISION",100,10);

                        doc.autoTable({html: '#producto'});


                doc.save("cotizacion.pdf");

                        this.cotizar==0;
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                        title: `${result.value.login}'s avatar`,
                        imageUrl: result.value.avatar_url
                        })
                    }
                    })




           },

                 /**************************************************************************************** */
            listarCliente (buscarB,criterioB){
                 let me=this;
                var url= '/cliente/listarCliente?buscarB='+ buscarB + '&criterioB='+ criterioB;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayCliente = respuesta.cliente.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
             /**************************************************************************************** */





            registrarVentap(){

                let cambio= this.total - this.recibido;


               // validarUser();
/*
                 console.log(this.idlugar);
                  console.log(this.idlugar[0]);
                  console.log(this.idlugar[1]);
                  */

                    if (this.validarVentap()){
                    return;
                }
             /*
                console.log(this.idlugar);
                console.log(this.total);
                console.log(this.totalc);
                console.log(this.totalu);
                 console.log(this.recibido);
                */


                let me = this;
                me.deshabilitar_boton=1;




                axios.post('/ventap/registrar',{
                    'idcliente': this.idcliente,
                    'tipo_comprobante': this.tipo_comprobante,
                    'num_comprobante': this.num_comprobante,
                     'idlugar' : this.idlugar[0],

                    //'serie_comprobante' : this.serie_comprobante,
                    //'num_comprobante' : this.num_comprobante,
                    //'impuesto' : this.impuesto,
                    'totalc' : this.totalc,
                    'totalu' : this.totalu,
                    'total' : this.total,

                    'data': this.arrayDetalle

                }).then(function (response) {
                   // console.log(response);
                    me.listado=1;
                    me.listarVentap(1,'','fecha_hora',me.idlugar2);

                    me.idcliente=0;

                    me.tipo_comprobante='POS';
                    //me.serie_comprobante='';
                    //me.num_comprobante='';
                    //me.impuesto=0.18;
                    me.total=0.0;
                    me.totalc=0.0;
                    me.totalu=0.0;
                    me.idproducto=0;
                    me.producto='';
                    me.cantidad=0;
                    me.costo=0;
                    me.precio=0;
                    me.descuento=0;
                    me.recibido=0;
                    me.arrayDetalle=[];
                    me.arrayCliente=[];



                     me.cerrarModal();
                     me.cerrarModalC();




                    Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1000

                        });

                        setTimeout(function(){

                            window.open('/ventap/pdf/'+response.data.id+','+'_blank');


                        }, 500);


                   me.deshabilitar_boton=0;

                }).catch(function (error) {
                    console.log(error);
                });


            },

             /**************************************************************************************** */

            validarUser(){

               // window.location.href = '/logout';

/*
                axios.post('/user/activado',{


                }).then(function (response) {

                    console.log(response);
                  if(response){

                  }
                  else{

                        window.location.href = 'https://127.0.0.1:8000/logout';



                  }




                }).catch(function (error) {
                    console.log(error);
                });
*/
            },


            validarVentap(){
                this.errorVentap=0;
                this.errorMostrarMsjVentap =[];

                if (this.idcliente==0) this.errorMostrarMsjVentap.push("Seleccione un Cliente");
                if (this.idlugar[0]=="") this.errorMostrarMsjVenta.push("Seleccione una Tienda");
                if (this.tipo_comprobante==0) this.errorMostrarMsjVentap.push("Seleccione el comprobante");
                if (!this.num_comprobante) this.errorMostrarMsjVentap.push("Ingrese el número de comprobante");
                //if (!this.impuesto) this.errorMostrarMsjVentap.push("Ingrese el impuesto de ventap");
                if (this.arrayDetalle.length<=0) this.errorMostrarMsjVentap.push("Ingrese detalles");
/*
                if(this.recibido==0) {
                   this.errorMostrarMsjVentap.push("El Monto recibido no puede ser 0");
                }
                if(this.cambio<0) {
                    this.errorMostrarMsjVentap.push("El Cambio no puede ser Negativo");
                }
*/
                if (this.errorMostrarMsjVentap.length) this.errorVentap = 1;

                return this.errorVentap;
            },


            validarCotizacion(){
                this.errorVentap=0;
                this.errorMostrarMsjVentap =[];


               // if (!this.num_comprobante) this.errorMostrarMsjVentap.push("Ingrese el número de comprobante");
                //if (!this.impuesto) this.errorMostrarMsjVentap.push("Ingrese el impuesto de ventap");
                if (this.arrayDetalle.length<=0) this.errorMostrarMsjVentap.push("Ingrese detalles");



                if (this.errorMostrarMsjVentap.length) this.errorVentap = 1;

                return this.errorVentap;
            },


              validarCliente(){


               this.errorCliente=0;
                this.errorMostrarMsjCliente  =[];


                if (!this.nit){
                  this.errorMostrarMsjCliente .push("El nit no puede estar vacío.");

                }
                if (!this.nombre){
                   this.errorMostrarMsjCliente.push("El nombre no puede estar vacio.");

                }
                /*
                if (!this.direccion){
                  this.errorMostrarMsjCliente.push("La Direccion no puede estar vacío.");

                }

                if (!this.telefono){
                  this.errorMostrarMsjCliente.push("El telefono no puede estar vacío.");

                }
                */


                if (this.errorMostrarMsjCliente.length) this.errorCliente = 1;

                return this.errorCliente;

            },

             /**************************************************************************************** */
              duplicado(){

               axios.put('/cliente/validar',{

                    'nit': this.nit,
                    'nombre': this.nombre,


                }).then(response => {
                  this.mensaje = response.data;
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },
            mostrarDetalle(){
                let me=this;
                me.listado=0;

                me.idcliente=0;
                me.nit='';
                me.nombre='';
                me.direccion='';
                me.telefono='';
                me.tipo_comprobante='POS';
                me.serie_comprobante='';
                me.num_comprobante='';
                //me.impuesto=0.18;
                me.total=0.0;
                me.totalc=0.0;
                me.totalu=0.0;
                me.idproducto=0;
                me.producto='';
                me.cantidad=0;
                me.precio=0;
                me.arrayDetalle=[];
            },

             /**************************************************************************************** */
            ocultarDetalle(){
                this.listado=1;
                this.cerrarModal();
            },

             /**************************************************************************************** */
            verVentap(id){
                let me=this;
                me.listado=2;

                //Obtener los datos del ventap
                var arrayVentapT=[];
                var url= '/ventap/obtenerCabecera?id=' + id;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    arrayVentapT = respuesta.ventasp;

                    me.cliente = arrayVentapT[0]['nombre'];
                    me.tipo_comprobante=arrayVentapT[0]['tipo_comprobante'];
                    me.num_comprobante=arrayVentapT[0]['num_comprobante'];
                    me.estado=arrayVentapT[0]['estado'];
                    //me.impuesto=arrayVentapT[0]['impuesto'];

                    me.total=arrayVentapT[0]['total'];
                    me.totalc=arrayVentapT[0]['totalc'];
                    me.totalu=arrayVentapT[0]['totalu'];

                })
                .catch(function (error) {
                    console.log(error);
                });

                //Obtener los datos de los detalles
                var urld= '/ventap/obtenerDetalles?id=' + id;

                axios.get(urld).then(function (response) {
                    console.log(response);
                    var respuesta= response.data;
                    me.arrayDetalle = respuesta.detalles;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             /**************************************************************************************** */
            cerrarModal(){
                this.modal=0;
                this.tituloModal='';
                this.modal2=0;
                this.recibido='';
                this.cambio='';
                this.desbloquear=0;
                this.deshabilitar_boton=0;



                this.arrayBuscarCliente=[];
                this.bcliente="";


            },

             /**************************************************************************************** */
            abrirModal(){
                this.arrayProducto=[];
                this.modal = 1;
                this.tituloModal = 'Seleccione uno o varios productos';
            },


             abrirModalAlarma(modelo2, accion, data = []){
              switch(modelo2){
                    case "producto":
                    {
                      switch(accion){
                        case 'alarma':
                            {
                              this.modal2=1;
                              this.tituloModal2='Stock Minimo';
                              break;
                            }
                      }
                    }
              }

            },

             /**************************************************************************************** */
             /*
            desactivarVentap(id){
               swal.fire({
                title: 'Esta seguro de anular este ventap?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
                }).then((result) => {
                if (result.value) {
                    let me = this;

                    axios.put('/ventap/desactivar',{
                        'id': id
                    }).then(function (response) {
                        me.listarVentap(1,'','tipo_comprobante');
                        swal.fire(
                        'Anulado!',
                        'El ventap ha sido anulado con éxito.',
                        'success'
                        )
                    }).catch(function (error) {
                        console.log(error);
                    });


                } else if (
                    // Read more about handling dismissals
                    result.dismiss === swal.DismissReason.cancel
                ) {

                }
                })
            },
*/
             desactivarVentap(id,num_comprobante,total){

                 let me = this;
                 let lugar2 = this.idlugar2;


                 var urld= '/ventap/obtenerDetalles?id=' + id +'&num_comprobante='+num_comprobante;

                axios.get(urld).then(function (response) {
                    console.log(response);
                    var respuesta= response.data;
                    me.arrayAnularV = respuesta.detalles;



                })
                .catch(function (error) {
                    console.log(error);
                });

             this.detallelist(id,lugar2,num_comprobante,total);


                },


         /**************************************************************************************** */
           detallelist(id,lugar2,num_comprobante,total){
/*
               Swal.fire({
                title: 'Esta seguro/a que desea Anular esta Venta?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {


                if (result.value) {
                  let me = this;

                  console.log(total);
                      console.log(num_comprobante);
                       console.log(lugar2);
                        console.log(id);
                        console.log(this.arrayAnularV);

                axios.put('/venta/desactivar',{
                          'id': id,
                          'num_comprobante':num_comprobante,
                          'total':total,
                          'lugar2': lugar2,
                          'data': this.arrayAnularV


                        }).then(function(response){
                          //  console.log(response);
                          me.listarVenta(1,'','fecha_hora',lugar2);
                          me.arrayAnularV=[];
                          Swal.fire(
                            'Anulado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });
                 // console.log(this.arrayAnularV);
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })

*/

               Swal.fire({
                    title: 'Razon de Anulacon:',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    preConfirm: (anular) => {

/*
                          if (this.validarVentap()){
                                   return;
                             }
*/
                             if(!anular){

                             }else{



                  let me = this;
                  /*
                  console.log(total);
                      console.log(num_comprobante);
                       console.log(lugar2);
                        console.log(id);
                        console.log(this.arrayAnularV);
                     */
                axios.put('/ventap/desactivar',{
                          'id': id,
                          'num_comprobante':num_comprobante,
                          'total':total,
                          'anular': anular,
                          'lugar2': lugar2,
                          'data': this.arrayAnularV


                        }).then(function(response){
                          //  console.log(response);
                          me.listarVentap(1,'','fecha_hora',lugar2);
                          me.arrayAnularV=[];


                              Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Anulacion Exitosa!',
                          showConfirmButton: false,
                          timer: 1500

                        });

                        }).catch(function(error){
                          console.log(error);
                        });


                             }

                    },
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                        title: `${result.value.login}'s avatar`,
                        imageUrl: result.value.avatar_url
                        })
                    }
                    })

           },


           VerAnulacion(id,num_comprobante,total)
           {

              let me=this;
                var url= '/venta/veranulacion';
                axios.post(url).then(function (response) {

                    var respuesta= response.data;
                    me.arrayAnulacion = respuesta.anular;

                     if(me.arrayAnulacion.length>0){



                       me.razonAnulacion=me.arrayAnulacion[0]["descripcion"];




                    Swal.fire({
                     icon: 'Anulada',
                     title: 'Ver Razon',
                     text: 'Razon: '+me.razonAnulacion,

                    })

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

           },

                  eliminarArticulo(id,cantidad,precio,descuento,idventap,idproducto,producto,costo){

                let me = this;


                let totav = document.getElementById("totalVentaArticulo").innerHTML;
                let totalc = me.totalc;
                let totalu = me.totalu;
               let estado = me.estado;
               let resta = (precio*cantidad-descuento).toFixed(2);
               let restac = (costo*cantidad).toFixed(2);

               let restaTotalc = (totalc - restac).toFixed(2);
               let restaTotal =  (totav - resta).toFixed(2);
               let restaTotalu = (restaTotal - restaTotalc).toFixed(2);
               let lugar2 = me.idlugar2;
               let comp = me.num_comprobante;
            /*
             console.log(restac);
             console.log(restaTotal);
               console.log(restaTotalc);
               console.log(restaTotalu);

               console.log('Estado: '+estado);
               console.log('TotalV: '+totav);

               console.log('comp2: '+comp);
                console.log('idetalle:' +id);
                console.log('detalle: '+cantidad);
                console.log('Precio: '+precio);
                console.log('Descuento: '+descuento);
                console.log('idventa: '+idventap);
                console.log('idproducto: '+idproducto);
                console.log('Nombreproducto: '+producto);
             */
                 if(estado=='Registrado'){

                    // console.log('Dentro');

                     Swal.fire({
                title: 'Esta seguro/a que desea Anular este Articulo?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                  var arrayVentaT=[];
                        axios.put('/ventap/earticulo',{
                          'id': id,
                          'idventa':idventap,
                          'idproducto':idproducto,
                          'producto':producto,
                          'lugar2':lugar2,
                          'comp': comp,
                          'cantidad':cantidad,
                          'resta':resta,
                          'restaTotal':restaTotal,
                          'restaTotalc':restaTotalc,
                          'restaTotalu':restaTotalu,




                        }).then(function(response){
                          me.listarVentap(1,'','fecha_hora',me.idlugar2);

                          Swal.fire(
                            'Anulado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                          console.log(response);
                           me.verVentap(idventap);

                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }

              })


                 } else{

                     Swal.fire({
                        icon: 'error',
                        title: 'Ya Esta Anulada...',
                        text: 'La Totalidad de esta Venta ya Esta Anulada!'

                        })
                 }


                /*
              console.log('NumeroV:'+numV);
              console.log('Nombre:'+producto);
              console.log('totaldeventa:'+totav);

                console.log('cantidad:'+cantidad);
                console.log('Precio:'+precio);
                console.log('Descuento:'+descuento);
               console.log('subtotal:'+resta);
                console.log('total-subtotal:'+restaTotal);
              */

            },


                 cerrarModalB(){
                this.modalB=0;
                this.tituloModalB='';
            },
            abrirModalB(){
                this.arrayCliente=[];
                this.modalB = 1;
                this.tituloModalB = 'Seleccione el cliente';
            },

            cerrarModalC(){
                this.modalC=0;
                this.tituloModalC='';

                this.nit='';
                this.nombrep='';
                this.direccion='';
                this.telefono='';
                this.errorCliente=0;
                this.val1=0;
                this.val2=0;
                this.val3=0;
                this.val4=0;
                this.deshabilitar_boton=0;
            },
            abrirModalC(){

                this.nit='';
                this.nombre='';
                this.direccion='';
                this.telefono='';

                this.modalC = 1;
                this.tituloModalC = 'Registrar Cliente';

            },

             mostrarDetalle(){
                let me=this;
                me.listado=0;

                me.idcliente=0;
                me.nombre='';
                me.direccion='';
                me.telefono='';
                me.tipo_comprobante='POS';
                me.serie_comprobante='';
                me.num_comprobante='';
                //me.impuesto=0.18;
                me.total=0.0;
                me.totalc=0.0;
                me.totalu=0.0;
                me.idproducto=0;
                me.producto='';
                me.cantidad=0;
                me.precio=0;
                me.costo=0;
                me.arrayDetalle=[];
            },
            ocultarDetalle(){
                this.listado=1;
                this.cerrarModal();
            },

        },
        mounted() {
            this.selectLugar2();
            this.listarVentap(1,this.buscar,this.criterio,this.idlugar2);
            this.selectLugar();

        }
    }
</script>
<style>
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
    }
    .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }
    @media (min-width: 600px) {
        .btnagregar {
            margin-top: 2rem;
        }
    }

</style>
