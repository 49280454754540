<template>
    <!-- Main content -->
    <div class="content">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Caja</h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-6"></div>
                    <!-- /.col -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card ">
                        <div class="card-header">

                            <button
                                type="button"
                                @click="abrirModal('caja', 'Entrada')"
                                class="btn btn-success mb-2"
                            >
                                <i class="fas fa-money-bill-wave"></i>
                                &nbsp;Entrada</button
                            >&nbsp;

                            <button
                                type="button"
                                @click="abrirModal('caja', 'Salida')"
                                class="btn btn-danger mb-2"
                            >
                                <i class="fas fa-money-bill-wave"></i>
                                &nbsp;Salida</button>&nbsp;
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <select
                                            name=""
                                            id=""
                                            class="form-control col-md-3"
                                            v-model="criterio"
                                        >
                                            <option value="fecha_hora"
                                                >Fecha_hora</option
                                            >
                                        </select>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Buscar"
                                            v-model="buscar"
                                            disabled
                                            @keyup.enter="
                                                listarCaja(1, buscar, criterio)
                                            "
                                        />
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                            @click="
                                                listarCaja(1, buscar, criterio)
                                            "
                                        >
                                          <i class="fas fa-search"></i>&nbsp;Buscar
                                        </button>

                                    </div>

                                     <p>Total Entrada Q {{totalEntrada=calcularTotalE}}</p>
                                        <p>Total Salida Q {{totalSalida=calcularTotalS}}</p>
                                        <h5>Total: Q{{total=(parseFloat(totalEntrada).toFixed(2) - parseFloat(totalSalida).toFixed(2))}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive">
                        <table  class="table table-bordered table-strined table-light">
                             <thead class="thead-dark">
                                <tr>
                                   <th>No</th>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Monto</th>
                                    <th>Razon</th>
                                    <th>Descripcion</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(caja,index) in arrayCaja" :key="caja.id">

                                  <td>{{(index + 1)}}</td>
                                   <td>
                                        <div v-if="caja.tipo">
                                             <span class="badge badge-danger">
                                                Salida
                                            </span>

                                        </div>
                                        <div v-else>
                                          <span class="badge badge-success">
                                                Entrada
                                            </span>

                                        </div>
                                    </td>

                                    <td v-text="caja.fecha_hora"></td>
                                    <td v-text="caja.monto"></td>
                                    <td v-text="caja.razon"></td>
                                    <td v-text="caja.descripcion"></td>


                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                        <div class="card-footer clearfix">
                            <nav>
                                <ul
                                    class="pagination pagination-sm m-0 float-right"
                                >
                                    <li
                                        class="page-item"
                                        v-if="pagination.current_page > 1"
                                    >
                                        <a
                                            class="page-link"
                                            href="#"
                                            @click="
                                                cambiarPagina(
                                                    pagination.current_page - 1,
                                                    buscar,
                                                    criterio
                                                )
                                            "
                                            >&laquo;</a
                                        >
                                    </li>
                                    <li
                                        class="page-item"
                                        v-for="page in pagesNumber"
                                        :key="page"
                                        :class="[
                                            page == isActived ? 'active' : ''
                                        ]"
                                    >
                                        <a
                                            class="page-link"
                                            href="#"
                                            @click="
                                                cambiarPagina(
                                                    page,
                                                    buscar,
                                                    criterio
                                                )
                                            "
                                            v-text="page"
                                        ></a>
                                    </li>
                                    <li
                                        class="page-item"
                                        v-if="
                                            pagination.current_page <
                                                pagination.last_page
                                        "
                                    >
                                        <a
                                            class="page-link"
                                            href="#"
                                            @click="
                                                cambiarPagina(
                                                    pagination.current_page + 1,
                                                    buscar,
                                                    criterio
                                                )
                                            "
                                            >&raquo;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!-- /.card-body -->
            </div>
            <!-- /.card -->
        </div>

        <!-- Modal de Registro Actualizar -->
        <div tabindex="-1" class="modal fade p-5" :class="{ mostrar: modal }">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header bg-primary">
                        <h4 class="modal-title" v-text="tituloModal"></h4>
                        <button
                            type="button"
                            class="close"
                            @click="cerrarModal()"
                        >
                            <span aria-hidden="true" class="text-white"
                                >&times;</span
                            >
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                        <form
                            action=""
                            method="post"
                            enctype="multipart/form-data"
                            class="form-horizontal"
                        >
                            <div class="form-group row">
                                <label
                                    for="recipient-name"
                                    class="col-form-label"
                                    >Monto:</label
                                >
                                <input
                                    type="number"
                                    class="form-control"

                                    v-model="monto"
                                />
                            </div>

                            <div class="form-group row">
                                <label for="message-text" class="col-form-label"
                                    >Razon:</label
                                >
                                <input
                                    type="text"
                                    class="form-control"

                                    v-model="razon"
                                />
                            </div>
                            <div v-show="val2">
                                <p class="alert alert-danger">
                                    El Nombre no puede estar vacío.
                                </p>
                            </div>
                            <div class="form-group row">
                                <label for="message-text" class="col-form-label"
                                    >Descripcion:</label
                                >
                                <input
                                    type="text"
                                    class="form-control"

                                    v-model="descripcion"
                                />
                            </div>


                            <div
                                v-show="errorCaja"
                                class="form-growp row  div-error"
                            >
                             <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjCaja" :key="error" v-text="error">

                                        </div>
                                    </div>
                            </div>
                        </form>
                        </div>
                    </div>
                    <div class="modal-footer ">
                        <button
                            type="button"
                            class="btn btn-default"
                            @click="cerrarModal()"
                        >
                            Cerrar
                        </button>
                        <button type="button" class="btn btn-primary" :disabled = "deshabilitar_boton==1"  @click="registrarCaja()">
                            Guardar
                        </button>

                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.Modal de Registro Actualizar  -->

        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->

    <!-- /.content -->
</template>

<script>
export default {
    data() {
        return {
            caja_id: 0,
            fecha_hora: "",

            monto: 0.0,
            razon: "",
            descripcion: "",
            tipo: "",
            arrayCaja: [],
            modal: 0,
            tituloModal: "",
            tipo: 0,
            deshabilitar_boton:0,
            errorCaja: 0,
            errorMostrarMsjCaja: [],

            totalSalida: 0.00,
            totalEntrada: 0.00,
            total: 0.00,
            val1: 0,
            val2: 0,
            val3: 0,
            val4: 0,
            pagination: {
                total: 0,
                current_page: 0,
                per_page: 0,
                last_page: 0,
                from: 0,
                to: 0
            },
            offset: 3,
            criterio: "fecha_hora",
            buscar: ""
        };
    },
    computed: {
        isActived: function() {
            return this.pagination.current_page;
        },
        //Calcula los elementos de la paginación
        pagesNumber: function() {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }

            var to = from + this.offset * 2;
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },

        calcularTotalS: function(){
                var resultado=0.00;

                for(var i=0;i<this.arrayCaja.length;i++){

                    if(this.arrayCaja[i].tipo==1){
                      resultado +=parseFloat(this.arrayCaja[i].monto)

                    }
                }
                return resultado.toFixed(2);

            },

        calcularTotalE: function(){
                var resultado=0.00;
                for(var i=0;i<this.arrayCaja.length;i++){

                    if(this.arrayCaja[i].tipo==0){
                      resultado +=parseFloat(this.arrayCaja[i].monto)

                    }
                }
                return resultado.toFixed(2);
            }
    },
    methods: {
        listarCaja(page, buscar, criterio) {

            let me = this;

            var url =
                "/cajatienda?page=" +
                page +
                "&buscar=" +
                buscar +
                "&criterio=" +
                criterio
            axios
                .get(url)
                .then(function(response) {
                   // console.log(response);
                    var respuesta = response.data;
                    me.arrayCaja = respuesta.caja.data;
                    me.pagination = respuesta.pagination;

                })
                .catch(function(error) {
                    console.log(error);
                });
        },

        cambiarPagina(page, buscar, criterio) {
            let me = this;
            //Actualiza la página actual
            me.pagination.current_page = page;
            //Envia la petición para visualizar la data de esa página
            me.listarCaja(page, buscar, criterio);
        },

        registrarCaja() {

            if (this.validarCaja()) {
                return;
            }

           let me = this;
                me.deshabilitar_boton=1;

            axios
                .post("/cajav/registrar", {


                    monto: this.monto,
                    razon: this.razon,
                    descripcion: this.descripcion,
                    tipo: this.tipo,


                })
                .then(function(response) {



                    me.listarCaja(1, "", "fecha_hora");

                    me.cerrarModal();
                     Swal.fire({
                        position: "top",
                        icon: "success",
                        title: "Datos Agregados Con Exito!",
                        showConfirmButton: false,
                        timer: 1000
                    });

                   me.deshabilitar_boton=0;
                })
                .catch(function(error) {
                    console.log(error);
                });


        },

        validarCaja() {


            this.errorCaja = 0;
            this.errorMostrarMsjCaja = [];

            if (!this.monto) {
                this.errorMostrarMsjCaja.push("El monto no puede estar vacío.");

            }
            if (!this.razon) {
                this.errorMostrarMsjCaja.push(
                    "La Razon no puede estar vacio."
                );

            }


            if (this.errorMostrarMsjCaja.length) this.errorCaja = 1;

            return this.errorCaja;
        },


        cerrarModal() {
            this.modal = 0;
            this.tituloModal = "";
            this.fecha_hora = "";
            this.monto = 0.00;
            this.razon = "";
            this.descripcion = "";
            this.errorCaja = 0;
            this.tipo="";
            this.val1 = 0;
            this.val2 = 0;
            this.val3 = 0;
            this.val4 = 0;
        },
        abrirModal(modelo, accion, data = []) {
            switch (modelo) {
                case "caja": {
                    switch (accion) {
                        case "Entrada": {
                            this.modal = 1;
                            this.tituloModal = "Entrada de Caja";
                            this.fecha_hora = "";
                            this.monto = "";
                            this.razon = "";
                            this.descripcion = "";
                            this.tipo = 0;
                            break;
                        }
                        case "Salida": {
                            //console.log(data);
                            this.modal = 1;
                            this.tituloModal = "Salida de Caja";
                            this.fecha_hora = "";
                            this.monto = "";
                            this.razon = "";
                            this.descripcion = "";
                            this.tipo = 1;

                            break;
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.listarCaja(1, this.buscar, this.criterio);

    }
};
</script>
<style>
.modal-content {
    width: 100% !important;
    position: absolute !important;
}

.mostrar {
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;
}

.diverror {
    display: flex;
    justify-content: center;
}

.text-error {
    color: red;
    font-weight: bold;
}
</style>
