<template>



    <!-- Main content -->    
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Ventas Pos Utilidad Mes</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">
       
      <div class="row">
        
<div class="col-12">
            

            <div class="card ">
              <div class="card-header">
                
               
                 <div class="form-group row">
                   <div class="col-md-12">
                     <div class="form-inline">

                       <label for="FormControlSelect1">Tienda: </label>
                       <select class="form-control" id="FormControlSelect1" v-model="lugar">
                        <option value="0" disabled>Seleccione</option>
                        <option v-for="lugar in arrayLugar" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                       </select>
                       
                    
                       <label for="FormControlSelect2">Año: </label>
                       <select name="" id="FormControlSelect2" class="form-control col-md-3" v-model="ao">
                         <option value="2021">2021</option>
                         <option value="2022">2022</option>
                         <option value="2023">2023</option>
                         <option value="2024">2024</option>
                         <option value="2025">2025</option>
                         <option value="2026">2026</option>
                         <option value="2027">2027</option>
                         <option value="2028">2028</option>
                         <option value="2029">2029</option>
                         <option value="2030">2030</option>
                       </select>&nbsp;
                      

                     
                    
                       
                       
                        <button type="submit" class="btn btn-primary" @click.prevent="getVentaps(lugar,ao)"><i class="fa fa-search"></i>&nbsp;Buscar</button>
                     </div>
                      
                      <div v-show="errorReporte" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjReporte" :key="error" v-text="error">

                                        </div>
                                    </div>
                  </div>
                   </div>
                 </div>

               </div>
                
                <div>

                    <div class="col-md-8">
                        <div class="card card-chart">
                            <div class="card-header">
                                <h4>Ventas Pos Utilidad</h4>
                            </div>
                            <div class="card-content">
                                <div class="ct-chart">
                                    <canvas id="ventaps">                                                
                                    </canvas>
                                </div>
                            </div>
                            <div class="card-footer">
                                <p>Ventas Pos de los últimos meses.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
              </div>
              <!-- /.card-header -->
              
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>

    


 
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  
  <!-- /.content -->
</template>
<script>




export default {
  
       data (){
            return {
              
                lugar:0,
                
                ao:0,

                 varVentap:null,
                charVentap:null,
                ventaps:[],
                varTotalVentap:[],
                varMesVentap:[], 
                arrayReporte : [],
                arrayRol : [],
                arrayLugar : [],
                modal : 0,
                errorReporte : 0,
                errorMostrarMsjReporte : [],
                'mensaje':'',
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                    
                },

                offset: 3,
            criterio: "fecha_hora",
            buscar: ""
                
            }
        },
        

  

        computed:{

       
       isActived: function() {
            return this.pagination.current_page;
        },
        //Calcula los elementos de la paginación
        pagesNumber: function() {
            if (!this.pagination.to) {
                return [];
            }

            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }

            var to = from + this.offset * 2;
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },

         
        },
        methods : { 



        getVentaps(lugar,ao){

   let me=this;


     

                

      if (this.validarReporte()){
                    return;
                }
              

                
                var url= '/ventaposmes?lugar=' + lugar + '&ao='+ ao  ;
                axios.get(url).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    me.ventaps = respuesta.ventaps;
                    //cargamos los datos del chart
                    me.loadVentaps();
                })
                .catch(function (error) {
                    console.log(error);
                });
            },


             loadVentaps(){
                let me=this;

                me.charVentap="";
               
                me.varMesVentap.splice(0, this.varMesVentap.length);
                me.varTotalVentap.splice(0, this.varTotalVentap.length);
          
                me.ventaps.map(function(x){
                    me.varMesVentap.push(x.mes);
                    me.varTotalVentap.push(x.total);
                });
                me.varVentap=document.getElementById('ventaps').getContext('2d');

                me.charVentap = new Chart(me.varVentap, {
                    type: 'bar',
                    data: {
                        labels: me.varMesVentap,
                        datasets: [{
                            label: 'Ventaps',
                            data: me.varTotalVentap,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgba(54, 162, 235, 0.2)',
                            borderWidth: 1
                        }]
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero:true
                                }
                            }]
                        }
                    }
                });



            },

      

             selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },


        
             validarReporte(){


             
                this.errorReporte=0;
                this.errorMostrarMsjReporte  =[];

              

                if (this.ao==0){
                   this.errorMostrarMsjReporte.push("seleccione un Año.");
                  
                } 
                
                if (this.lugar==0){
                  this.errorMostrarMsjReporte.push("Seleccione un Lugar.");
                  
                }
                
                

                if (this.errorMostrarMsjReporte.length) this.errorReporte = 1;
                    return this.errorReporte;
               
             
            },





            resetCanvas(){



            },


           
        },
        mounted() {
            
             this.selectLugar();
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>