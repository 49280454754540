<template>






    <!-- Main content -->    
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Reporte Almacen</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">
       
      <div class="row">
        
              <div class="col-12">
            
                   <button type="button" class="btn btn-info">Inventario?</button>
          
              </div>
              <!-- /.card-body -->
     </div>
            <!-- /.card -->
   </div>

    


 
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  
  <!-- /.content -->
</template>
<script>




export default {
  
       data (){
            return {
                cliente_id: 0,
                arraycliente : [],
                arrayRol : [],
                modal : 0,
                errorcliente : 0,
                errorMostrarMsjcliente : [],
                'mensaje':'',
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                    
                },
                offset : 3,
                top : 5,
                de : '',
                a : '',
                lugar : 'Tienda'
            }
        },
        

  

        computed:{



         
        },
        methods : { 


            listarTop(page,lugar,de,a,top){


                 if (this.validarcliente()){
                    return;
                }

                /*

              if(lugar=="Tienda"){      
                let me=this;
                 var url= '/ventactop?page=' + page + '&de='+ de + '&a='+ a + '&top='+ top;
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arraycliente = respuesta.clientes.data;
                
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

              }


              if(lugar=="Bodega"){

                   let me=this;
                 var url= '/trackingctop?page=' + page + '&de='+ d + '&a='+ a + '&top='+ top;
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arraycliente = respuesta.clientes.data;
                  
                })
                .catch(function (error) {
                    console.log(error);
                });

              }
            */
               

                
            },

           


            


          

             validarcliente(){

/*
             
                this.errorcliente=0;
                this.errorMostrarMsjcliente  =[];

                if (!this.de){
                  this.errorMostrarMsjcliente .push("Uno de los Rangos de Fecha esta Vacio.");
                  
                }

                if (!this.a){
                   this.errorMostrarMsjcliente.push("Uno de los Rangos de Fecha esta Vacio.");
                  
                } 
                
                if (this.lugar==0){
                  this.errorMostrarMsjcliente.push("Seleccione un Lugar.");
                  
                }
                
                
               

                if (this.errorMostrarMsjcliente.length) this.errorcliente = 1;
               
              
                return this.errorcliente;
               
   */           
            },


           
        },
        mounted() {
            
             
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>
