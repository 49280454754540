require('./bootstrap');

import Vue from 'vue';

window.Vue = require('vue');

Vue.prototype.$axios = axios;

Vue.prototype.$api_url = "https://intec26102018.com";

Vue.component('tienda', require('./components/Tienda.vue').default);
Vue.component('rol', require('./components/Rol.vue').default);
Vue.component('user', require('./components/User.vue').default);

Vue.component('cliente', require('./components/Cliente.vue').default);
Vue.component('proveedor', require('./components/Proveedor.vue').default);
Vue.component('caja', require('./components/Caja.vue').default);
Vue.component('cajaadmin', require('./components/Cajaadmin.vue').default);

Vue.component('categoria', require('./components/Categoria.vue').default);

Vue.component('genero', require('./components/Genero.vue').default);
Vue.component('diseno', require('./components/Diseno.vue').default);
Vue.component('tela', require('./components/Tela.vue').default);
Vue.component('color', require('./components/Color.vue').default);
Vue.component('talla', require('./components/Talla.vue').default);
Vue.component('lote', require('./components/Lote.vue').default);

/** necesita pdf */
Vue.component('producto', require('./components/Producto.vue').default);
Vue.component('existencia', require('./components/Existencia.vue').default);

Vue.component('compra', require('./components/Compra.vue').default);
Vue.component('creditocompra', require('./components/Creditocompra.vue').default);
/** necesita pdf */
Vue.component('trasladosalida', require('./components/Trasladosalida.vue').default);
Vue.component('trasladoentrada', require('./components/Trasladoentrada.vue').default);
/** necesita pdf */
Vue.component('trasladosalidav', require('./components/Trasladosalidav.vue').default);
Vue.component('trasladoentradav', require('./components/Trasladoentradav.vue').default);

/********* tracking **********************/
Vue.component('tracking', require('./components/Tracking.vue').default);
Vue.component('entregada', require('./components/Entregada.vue').default);
Vue.component('pagada', require('./components/Pagada.vue').default);


Vue.component('trackingv', require('./components/Trackingv.vue').default);
Vue.component('entregadav', require('./components/Entregadav.vue').default);
Vue.component('pagadav', require('./components/Pagadav.vue').default);

Vue.component('reportetracking', require('./components/ReporteTracking.vue').default);
Vue.component('reporteentregada', require('./components/ReporteEntregada.vue').default);
Vue.component('reportepagada', require('./components/ReportePagada.vue').default);


/*** necesita pdf */
Vue.component('venta', require('./components/Venta.vue').default);
Vue.component('ventap', require('./components/Ventap.vue').default);
Vue.component('ventapadmin', require('./components/VentaPadmin.vue').default);
Vue.component('ventaadmin', require('./components/Ventaadmin.vue').default);
Vue.component('creditoventa', require('./components/Creditoventa.vue').default);
Vue.component('creditoventav', require('./components/Creditoventav.vue').default);

Vue.component('reportealmacen', require('./components/ReporteAlmacen.vue').default);
Vue.component('reportecompra', require('./components/ReporteCompra.vue').default);
Vue.component('reporteventa', require('./components/ReporteVenta.vue').default);
Vue.component('reporteventap', require('./components/Reporteventap.vue').default);
Vue.component('reportecliente', require('./components/ReporteCliente.vue').default);
Vue.component('reporteproveedor', require('./components/ReporteProveedor.vue').default);
Vue.component('reportetopcompra', require('./components/Reportetopcompra.vue').default);
Vue.component('reportetopventa', require('./components/Reportetopventa.vue').default);

Vue.component('reporteventacredito', require('./components/ReporteVentaCredito.vue').default);
Vue.component('reporteservicio', require('./components/ReporteServicio.vue').default);

Vue.component('kardex', require('./components/Kardex.vue').default);
Vue.component('comprames', require('./components/CompraMes.vue').default);
Vue.component('ventames', require('./components/VentaMes.vue').default);
Vue.component('ventapmes', require('./components/VentapMes.vue').default);


Vue.component('servicio', require('./components/Servicio.vue').default);
Vue.component('serviciov', require('./components/Serviciov.vue').default);

Vue.component('garantia', require('./components/Garantia.vue').default);
Vue.component('garantiav', require('./components/Garantiav.vue').default);

Vue.component('cotizacion', require('./components/Cotizacion.vue').default);
Vue.component('cotizacionv', require('./components/Cotizacionv.vue').default);

Vue.component('anticipo', require('./components/Anticipo.vue').default);
Vue.component('anticipoadmin', require('./components/AnticipoAdmin.vue').default);


/** necesita pdf */
Vue.component('salidainv', require('./components/Salidainv.vue').default);
Vue.component('entradainv', require('./components/Entradainv.vue').default);

/** necesita pdf */
Vue.component('comisiones', require('./components/Comisiones.vue').default);


Vue.component('notificacion', require('./components/Notificacion.vue').default);




const app = new Vue({
    el: '#app',
    data:{
        menu: 0,
    }
});
