<template>


    <!-- Main content -->
 <div class="content">







 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="mb-2 row">
          <div class="col-sm-6">


            <h1 class="m-0 text-dark">Existencias</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">

          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>


    <div class="container-fluid">

      <div class="row">

<div class="col-12">


            <div class="card ">
              <div class="card-header">
                <form action="">
                  <!--
                 <button type="button" @click="abrirModal('producto','registrar')" class="mb-2 btn btn-success"><i class="fas fa-plus-square"></i>&nbsp;Nuevo</button>&nbsp;
                 -->

                 <div v-if="contarAlerta > 0">
               <button type="button" class="btn btn-danger" @click="abrirModalAlarma('producto','alarma')" ><i class="fas fa-bell"></i> {{contarAlerta}}</button>
            </div>
                  <div class="form-group">
                     <label for="exampleFormControlSelect1">Tienda</label>
                     <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar">
                       <option value="0" disabled>Seleccione</option>
                            <option v-for="lugar in arrayLugar" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                     </select>
                     </div>
                  <button type="button" @click="cargarPdf(idlugar)" class="mb-2 btn btn-info"><i class="fas fa-file-pdf"></i>&nbsp;Reporte</button>&nbsp;

                </form>

                 <div class="form-group row">
                   <div class="col-md-6">
                     <div class="input-group">
                       <select name="" id="" class="form-control col-md-3" v-model="criterio">
                         <option value="codigo">Codigo</option>
                         <option value="nombre">Nombre</option>
                         <option value="descripcion">Descripcion</option>
                         <option value="stock">Stock</option>
                       </select>
                       <input type="text" class="form-control" placeholder="Buscar" v-model="buscar" @keyup.enter="listarProducto(1,buscar,criterio,idlugar)">
                       <button type="submit" class="btn btn-primary" @click="listarProducto(1,buscar,criterio,idlugar)"><i class="fas fa-search"></i>&nbsp;Buscar</button>
                     </div>
                   </div>
                 </div>

               </div>


              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Opciones</th>
                       <th>Imagen</th>
                    <th>Lugar</th>
                    <th>Codigo</th>
                    <th>Categoria</th>
                    <!--
                    <th>Genero</th>
                    <th>Diseño</th>
                    <th>Tela</th>
                    <th>Color</th>
                    <th>Talla</th>
                    <th>Lote</th>
-->

                    <th>Nombre</th>
                    <th>Stock</th>



                    <th>Descripcion</th>
                    <th>Precio_Costo</th>
                    <th>Precio_venta</th>
                    <th>Minimo</th>
                    <th>Estado</th>


                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(producto,index) in arrayProducto" :key="producto.id" >
                    <td>
                      {{index+1}}
                    </td>
                    <td>
                      <!--
                       <button type="button" @click="abrirModal('producto','actualizar',producto)" class="btn btn-warning btn-sm"><i class="fas fa-edit"></i></button>&nbsp;
                     -->
                      <template v-if="producto.condicion">
                        <button type="button" class="btn btn-danger btn-sm" @click="desactivarProducto(producto.id)">
                          <i class="fas fa-trash-alt"></i>
                          </button>
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-info btn-sm" @click="activarProducto(producto.id)"><i class="far fa-thumbs-up"></i></button>
                      </template>

                    </td>
                    <td>
                      <div class="contentimg">
                         <img :src="url+producto.img" class="imgcrud" alt="">
                      </div>

                      </td>
                                   <td v-text="producto.nombre_lugar"></td>
                                   <td v-text="producto.codigo"></td>
                                    <td v-text="producto.nombre_categoria"></td>


                                    <!--

                                    <td v-text="producto.nombre_genero"></td>
                                    <td v-text="producto.nombre_diseno"></td>
                                    <td v-text="producto.nombre_tela"></td>
                                    <td v-text="producto.nombre_color"></td>
                                    <td v-text="producto.nombre_talla"></td>
                                    <td v-text="producto.nombre_lote"></td>
                                    -->

                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.nombre"></td>
                                    <td v-else v-text="producto.nombre"></td>

                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                    <td v-else v-text="producto.stock"></td>

                                    <td v-text="producto.descripcion"></td>
                                     <td v-text="producto.precio_costo"></td>
                                    <td v-text="producto.precio_venta"></td>

                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.minimo"></td>
                                    <td v-else v-text="producto.minimo"></td>




                                   <td>
                                       <div v-if="producto.condicion">
                                           <span class="badge badge-success"> Activo </span>
                                       </div>
                                       <div v-else>
                                          <span class="badge badge-danger"> Desactivado </span>
                                       </div>

                                    </td>

                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="clearfix card-footer">
                 <nav>
                <ul class="float-right m-0 pagination pagination-sm">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio,idlugar)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio,idlugar)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio,idlugar)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>






<div tabindex="-1" class="p-5 modal fade" :class="{'mostrar' : modal2}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal2"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">

                   <div class="card-body table-responsive">
                     <div class="container-fluid">
                  <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>


                    <th>Nombre</th>
                    <th>Stock</th>
                    <th>Descripcion</th>
                    <th>Minimo</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="producto in arrayProducto" :key="producto.id" >


                                    <td v-if="producto.stock <= producto.minimo"  v-text="producto.nombre"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                    <td v-if="producto.stock <= producto.minimo" v-text="producto.descripcion"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.minimo"></td>


                  </tr>

                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="clearfix card-footer">
                 <nav>
                <ul class="float-right m-0 pagination pagination-sm">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




<div tabindex="-1" class="p-5 modal fade" :class="{'mostrar' : modal}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                    <div style="display: none;" class="form-group ">
                      <label class="col-md-3 form-control-label" >Categoria </label>
                         <select   class="form-control" v-model="idcategoria">
                            <option value="0" disabled>Seleccione</option>
                            <option v-for="categoria in arrayCategoria" :key="categoria.id" :value="categoria.id" v-text="categoria.nombre"></option>
                        </select>
                    </div>

                      <div class="form-group ">
                      <label class="col-md-3 form-control-label" >Tienda </label>

                          <select disabled class="form-control"  v-model="idlugar">
                              <option value="0">Seleccione una Tienda</option>
                              <option v-for="lugar in arrayLugar" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                            </select>

                    </div>

                 <div  class="form-group row">
                    <label for="recipient-name" class="col-form-label">Nombre:</label>
                     <input disabled type="text" class="form-control" @blur="duplicado()"  v-model="nombre">
                  </div>

                  <div style="display: none;" class="form-group row">
                     <label for="message-text" class="col-form-label">Precio Costo:</label>
                      <input type="number" class="form-control"  v-model="precio_costo" >
                  </div>

                  <div style="display: none;" class="form-group row">
                     <label for="message-text" class="col-form-label">Precio venta:</label>
                      <input type="number" class="form-control"  v-model="precio_venta" >
                  </div>

                  <div  class="form-group row">
                     <label for="message-text" class="col-form-label">Stock:</label>
                      <input type="number" min="0" step="1" class="form-control" v-model="stock" >
                  </div>

                  <div style="display: none;" class="form-group row">
                     <label for="message-text" class="col-form-label">Descripción:</label>
                      <input type="text" class="form-control"  v-model="descripcion" >
                  </div>

                  <div style="display: none;" class="form-group row">
                     <label for="message-text" class="col-form-label">Minimo:</label>
                      <input type="number" min="0" step="1" class="form-control"  v-model="minimo" >
                  </div>


                   <div v-show="errorProducto" class="form-growp row div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjProducto" :key="error" v-text="error">
                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row div-error">
                    <p class="text-center text-error">El Producto ya Existe!</p>
                  </div>
                  </form>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
              <button type="button" v-if="tipoAccion==1" :disabled = "deshabilitar_boton==1"  class="btn btn-primary" @click="registrarProducto()">Guardar</button>
               <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizarProducto()">Actualizar</button>
            </div>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




      <!-- /.row -->
    </div><!-- /.container-fluid -->

  <!-- /.content -->
</template>
<script>




export default {

       data (){
            return {
                producto_id: 0,
                 idcategoria : 0,
                 idlugar : 0,
                nombre_categoria : '',
                nombre_lugar : '',
                codigo : '',
                nombre : '',
                lugar: '',
                url:'img/productos/',
                precio_costo : 0,
                precio_venta : 0,
                stock : 0,
                descripcion : '',
                minimo : 0,
                arrayProducto : [],
                arrayCategoria :[],
                arrayLugar :[],
                modal : 0,
                modal2 : 0,
                urlhttp: this.$api_url,
                deshabilitar_boton:0,
                tituloModal : '',
                tituloModal2 : '',
                tipoAccion : 0,
                errorProducto : 0,
                errorMostrarMsjProducto : [],
                'mensaje':'',
                'val1':0,
                    'val2':0,
                    'val3':0,
                    'val4':0,
                    'val5':0,
                    'val6':0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,

                },
                offset : 3,
                criterio : 'codigo',
                buscar : ''
            }
        },




        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;

            },

            contarAlerta: function(){

              var resultado=0;
                for(var i=0;i<this.arrayProducto.length;i++){

                    if(this.arrayProducto[i].stock <= this.arrayProducto[i].minimo){

                      resultado +=1;
                    }


                }
                return resultado;
            }
        },
        methods : {
            listarProducto (page,buscar,criterio,idlugar){



                let me=this;
                 var url= '/existencia?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio+ '&idlugar='+ idlugar;
                axios.get(url).then(function (response) {
                 //console.log(response);
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos.data;
                    me.pagination= respuesta.pagination;

                })
                .catch(function (error) {
                    console.log(error);
                });
            },


            cargarPdf(idlugar){

             console.log(idlugar);


              if(idlugar==0){

              }else{
                window.open('/existencia/pdf/'+idlugar+','+'_blank');
              }



            },

              selectCategoria(){
                let me=this;
                var url= '/categoria/selectCategoria';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayCategoria = respuesta.categorias;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

               cambiarPagina(page,buscar,criterio,idlugar){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarProducto(page,buscar,criterio,idlugar);
            },


            registrarProducto(){
                if (this.validarProducto()){
                    return;
                }

                let me = this;
                 me.deshabilitar_boton=1;
                axios.post('/existencia/registrar',{


                    'idcategoria': this.idcategoria,
                    'codigo': this.codigo,
                    'nombre': this.nombre,
                    'stock': this.stock,
                    'precio_venta': this.precio_venta,
                    'descripcion': this.descripcion,
                    'minimo': this.minimo

                }).then(function (response) {



                    me.listarProducto(1,'','codigo','idlugar');
                    me.contarAlerta();

                    me.cerrarModal();
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                        me.deshabilitar_boton=0;
                }).catch(function (error) {
                    console.log(error);
                });
            },


            actualizarProducto(){

               if (this.validarProducto()){
                    return;
                }

                this.detalle_id;
                let me = this;


                axios.put('/existencia/actualizar',{


                    'idcategoria': this.idcategoria,
                    'idlugar': this.idlugar,
                    'codigo': this.codigo,
                    'nombre': this.nombre,
                    'detalle_id' : this.detalle_id,
                    'stock': this.stock,
                    'precio_costo': this.precio_costo,
                    'precio_venta': this.precio_venta,
                    'descripcion': this.descripcion,
                    'minimo': this.minimo,

                    'id': this.producto_id


                }).then(function (response) {
                  //console.log(response);
                    me.cerrarModal();
                    me.listarProducto(1,'','codigo','idlugar');
                    me.contarAlerta();
                        Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });

                }).catch(function (error) {
                    console.log(error);
                });

            },


            duplicado(){

               axios.put('/existencia/validar',{

                    'codigo': this.codigo

                }).then(response => {
                  this.mensaje = response.data;
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },





             validarProducto(){


               this.errorProducto=0;
                this.errorMostrarMsjProducto  =[];


                if (this.idcategoria==0){ this.errorMostrarMsjProducto.push("Seleccione una categoría."); this.val1=1; }
                if (!this.codigo){ this.errorMostrarMsjProducto.push("El codigo del artículo no puede estar vacío."); this.val1=2; }
                if (!this.nombre){ this.errorMostrarMsjProducto.push("El nombre del artículo no puede estar vacío."); this.val1=1; }
                if (!this.stock){ this.errorMostrarMsjProducto.push("El stock del artículo debe ser un número y no puede estar vacío."); this.val4=1; }
                if (!this.precio_venta){ this.errorMostrarMsjProducto.push("El precio venta del artículo debe ser un número y no puede estar vacío."); this.val3=1; }
                if (!this.minimo){ this.errorMostrarMsjProducto.push("El Minimo del artículo debe ser un número y no puede estar vacío."); this.val6=1; }



                if (this.errorMostrarMsjProducto.length) this.errorProducto = 1;

                return this.errorProducto;

            },
            cerrarModal(){
                this.modal=0;
                this.modal2=0;
                this.tituloModal='';
                this.idcategoria= 0;
                this.nombre_categoria = '';
                this.codigo = '';
                this.nombre = '';
                this.precio_costo = 0;
                this.precio_venta = 0;
                this.stock = 0;
                this.descripcion = '';
                this.minimo = '';
                this.errorProducto=0;
                this.val1=0;
                this.val2=0;
                this.val3=0;
                this.val4=0;
            },
            abrirModal(modelo, accion, data = []){



                switch(modelo){
                    case "producto":
                    {
                        switch(accion){
                            case 'registrar':
                            {
                               this.modal = 1;
                                this.tituloModal = 'Registrar Producto';
                                this.idcategoria= 0;
                                this.idlugar= 0;
                                this.nombre_categoria = '';
                                this.codigo = '';
                                this.nombre = '';
                                this.precio_costo = 0;
                                this.precio_venta = 0;
                                this.stock = 0;
                                this.descripcion = '';
                                this.minimo = '';
                                this.tipoAccion = 1;
                                break;
                            }
                            case 'actualizar':
                            {
                                //console.log(data);
                                this.modal=1;
                                this.tipoAccion=2;
                                this.tituloModal='Actualizar Producto';
                                this.producto_id=data['id'];
                                this.idcategoria=data['idcategoria'];
                                this.detalle_id=data['detalle_id'];
                                this.idlugar=data['lugar'];
                                this.codigo = data['codigo'];
                                this.nombre = data['nombre'];
                                this.stock=data['stock'];
                                this.precio_costo=data['precio_costo'];
                                this.precio_venta=data['precio_venta'];
                                this.descripcion= data['descripcion'];
                                this.minimo= data['minimo'];

                                break;
                            }


                        }
                    }
                }

                this.selectCategoria();
                this.selectLugar();

            },


            abrirModalAlarma(modelo2, accion, data = []){
              switch(modelo2){
                    case "producto":
                    {
                      switch(accion){
                        case 'alarma':
                            {
                              this.modal2=1;
                              this.tituloModal2='Stock Minimo';
                              break;
                            }
                      }
                    }
              }

            },

           desactivarProducto : function(id){
              Swal.fire({
                title: 'Esta seguro/a que desea desactivar el Producto?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/existencia/desactivar',{
                          'id': id
                        }).then(function(response){
                          me.listarProducto(1,'','codigo','idlugar');
                          me.contarAlerta();
                          Swal.fire(
                            'Desactivado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })
                },


               activarProducto: function(id){

                Swal.fire({
                title: 'Esta seguro/a que desea activar Producto?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/existencia/activar',{
                          'id': id
                        }).then(function(response){
                         me.listarProducto(1,'','codigo','idlugar');
                         me.contarAlerta();
                          Swal.fire(
                            'activado!',
                            'El registro a sido activado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })


              }


        },
        mounted() {
            //this.listarProducto(1,this.buscar,this.criterio,this.tienda);
            this.selectLugar();
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>
