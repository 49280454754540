<template>

    <!-- Main content -->    
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Lote</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">
       
      <div class="row">
        
<div class="col-12">
            

            <div class="card ">
              <div class="card-header">
                
                 <button type="button" @click="abrirModal('lote','registrar')" class="btn btn-success mb-2"><i class="fas fa-plus-square"></i>&nbsp;Nuevo</button>&nbsp;
                 <div class="form-group row">
                   <div class="col-md-6">
                     <div class="input-group">
                       <select name="" id="" class="form-control col-md-3" v-model="criterio">
                         <option value="nombre">Nombre</option>
                         <option value="descripcion">Descripcion</option>
                       </select>
                       <input type="text" class="form-control" placeholder="Buscar" v-model="buscar" @keyup="listarLote(1,buscar,criterio)">
                       <button type="submit" class="btn btn-primary" @click="listarLote(1,buscar,criterio)"><i class="fas fa-search"></i>&nbsp;Buscar</button>
                     </div>
                   </div>
                 </div>

               </div>
                
                
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Opciones</th>
                 
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Estado</th>
                    
                    
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(lote,index) in arrayLote" :key="lote.id" >
                    <td>{{(index + 1)}}</td>
                    <td>
                       <button type="button" @click="abrirModal('lote','actualizar',lote)" class="btn btn-warning btn-sm"><i class="fas fa-edit"></i></button>&nbsp;
                      <template v-if="lote.condicion">
                        <button type="button" class="btn btn-danger btn-sm" @click="desactivarLote(lote.id)">
                          <i class="fas fa-trash-alt"></i>
                          </button> 
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-info btn-sm" @click="activarLote(lote.id)"><i class="far fa-thumbs-up"></i></button> 
                      </template>
                      
                    </td>
                                    
                                    
                                    <td v-text="lote.nombre"></td>
                                    <td v-text="lote.descripcion"></td>
                                   
                                    
                   <td>
                       <div v-if="lote.condicion">
                            <span class="badge badge-success"> Activo </span>
                       </div>
                       <div v-else>
                            <span class="badge badge-danger"> Desactivado </span>
                       </div>
                      
                   </td>
                 
                  </tr>
                 
                  
                  </tbody>
                  <tfoot>
                  
                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>   
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>






<div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                
                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Nombre:</label> 
                     <input type="text" class="form-control" @blur="duplicado()"  v-model="nombre">
                  </div>
                  
                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Descripcion:</label>
                      <input type="text" class="form-control"  v-model="descripcion" >
                  </div>
                  
                  
                   <div v-show="errorLote" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjLote" :key="error" v-text="error">
                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row  div-error">
                    <p class="text-center text-error">La Lote ya Existe!</p>
                  </div> 
                  </form>
                 </div> 
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
              <button type="button" v-if="tipoAccion==1" :disabled = "deshabilitar_boton==1"  class="btn btn-primary" @click="registrarLote()">Guardar</button>
               <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizarLote()">Actualizar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->
    


 
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  
  <!-- /.content -->
</template>
<script>




export default {
  
       data (){
            return {
                lote_id: 0,
                nombre : '',
                descripcion : '',
                arrayLote : [],
                modal : 0,
                tituloModal : '',
                tipoAccion : 0,
                errorLote : 0,
                errorMostrarMsjLote : [],
                 'mensaje':'',
                 deshabilitar_boton:0,
                'val1':0,
                    'val2':0,
                    'val3':0,
                    'val4':0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                    
                },
                offset : 3,
                criterio : 'nombre',
                buscar : ''
            }
        },
        

  

        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }
                
                var from = this.pagination.current_page - this.offset; 
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2); 
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }  

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;             

            }
        },
        methods : { 
            listarLote (page,buscar,criterio){
            
                let me=this;
                 var url= '/lote?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio;
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayLote = respuesta.lote.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            

            cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarLote(page,buscar,criterio);
            },



            registrarLote(){
                if (this.validarLote()){
                    return;
                }
                
                let me = this;

                axios.post('/lote/registrar',{
                  
                    
                    'nombre': this.nombre,
                    'descripcion': this.descripcion
                    
                    
                }).then(function (response) {
                  //console.log(response);
                    me.cerrarModal();
                    me.deshabilitar_boton=0;
                    me.listarLote(1,'','nombre');
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                }).catch(function (error) {
                    console.log(error);
                });
            },


            actualizarLote(){
              
               if (this.validarLote()){
                    return;
                }
                
                let me = this;
                
                   
                axios.put('/lote/actualizar',{
                    
                  
                    'nombre': this.nombre,
                    'descripcion': this.descripcion,
                  
                    'id': this.lote_id

                    
                }).then(function (response) {
                    me.cerrarModal();
                    me.listarLote(1,'','nombre');
                        Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                    
                }).catch(function (error) {
                    console.log(error);
                }); 
                
            },

           
           duplicado(){
             console.log(this.nombre);
               axios.put('/lote/validar',{

                 
                  
                    'nombre': this.nombre
                    
                }).then(response => {
                  this.mensaje = response.data;
                 // console.log(this.mensaje);
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },
          
           

             validarLote(){


               this.errorLote=0;
                this.errorMostrarMsjLote  =[];

                
               
                if (!this.nombre){
                   this.errorMostrarMsjLote.push("El nombre no puede estar vacio.");
                 
                }
                
                
                

                if (this.errorMostrarMsjLote.length) this.errorLote = 1;

                return this.errorLote;
              
            },
            cerrarModal(){
                this.modal=0;
                this.tituloModal='';
                this.nit='';
                this.nombre='';
                this.direccion='';
                this.telefono='';
                this.errorLote=0;
                this.val1=0;
                this.val2=0;
                
            },
            abrirModal(modelo, accion, data = []){

            

                switch(modelo){
                    case "lote":
                    {
                        switch(accion){
                            case 'registrar':
                            {
                               this.modal = 1;
                                this.tituloModal = 'Registrar Lote';
                                this.nombre='';
                                this.descripcion='';
                                this.tipoAccion = 1;
                                break;
                            }
                            case 'actualizar':
                            {
                                //console.log(data);
                                this.modal=1;
                                this.tipoAccion=2;
                                this.tituloModal='Actualizar Lote';
                                this.lote_id=data['id'];
                                this.nombre = data['nombre'];
                                this.descripcion = data['descripcion'];
                                
                    
                                break;
                            }
                        }
                    }
                }
              
            },
           
           desactivarLote : function(id){
              Swal.fire({
                title: 'Esta seguro/a que desea desactivar el Lote?',
                
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/lote/desactivar',{
                          'id': id
                        }).then(function(response){
                          me.listarLote(1,'','nombre');
                          Swal.fire(
                            'Desactivado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });

                  
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                 
                }
              })
                },
            

               activarLote: function(id){

                Swal.fire({
                title: 'Esta seguro/a que desea activar Lote?',
                
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/lote/activar',{
                          'id': id
                        }).then(function(response){
                         me.listarLote(1,'','nombre');
                          Swal.fire(
                            'activado!',
                            'El registro a sido activado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });

                  
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                 
                }
              })


              }

           
        },
        mounted() {
            this.listarLote(1,this.buscar,this.criterio);
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>
