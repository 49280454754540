<template>

    <!-- Main content -->
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="mb-2 row">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Servicio</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">

          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">

      <div class="row">

<div class="col-12">


            <div class="card ">
              <div class="card-header">

                 <button type="button" @click="abrirModal('Servicio','registrar')" class="mb-2 btn btn-success"><i class="fas fa-plus-square"></i>&nbsp;Nuevo</button>&nbsp;
                 <div class="form-group row">
                   <div class="col-md-6">


                                <div class="input-group">

                                    <select class="form-control col-md-3" v-model="criterio">
                                       <option value="num">Orden</option>
                                      <option value="nombre">Nombre</option>
                                      <option value="receptor">Receptor</option>
                                      <option value="fecha_hora">Fecha_hora</option>
                                      <option value="estado">Estado</option>
                                    </select>
                                    <input type="text" v-model="buscar" @keyup.enter="listarServicio(1,buscar,criterio)" class="form-control" placeholder="Texto a buscar">
                                    <button type="submit" @click="listarServicio(1,buscar,criterio)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                </div>
                   </div>
                 </div>

               </div>


              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                     <th>No</th>
                    <th>Opciones</th>
                    <th>Orden</th>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Telefono</th>
                    <th>Receptor</th>
                    <th>Monto</th>
                    <th>Estado</th>


                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(Servicio, index) in arrayServicio" :key="Servicio.id" >
                    <td>{{(index + 1)}}</td>
                    <td>
                        <button type="button" @click.prevent="verServicio(Servicio.id)" class="btn btn-success btn-sm">
                                            <i class="fas fa-eye"></i>
                        </button> &nbsp;
                         <button type="button" @click.prevent="pdfServicio(Servicio.id)" class="btn btn-info btn-sm">
                                            <i class="fas fa-file-pdf"></i>
                         </button> &nbsp;

                       <template v-if="Servicio.estado=='EnProceso'">
                                                <button type="button" class="btn btn-info btn-sm" @click.prevent="terminado(Servicio.id)">
                                                    <i class="fas fa-check"></i>
                                                </button>
                       </template>
                         <template v-if="Servicio.estado=='Terminado'">
                                                <button type="button" class="btn btn-success btn-sm" @click.prevent="entregado(Servicio.id, Servicio.monto)">
                                                    <i class="fas fa-check"></i>
                                                </button>
                         </template>

                    </td>


                                  <td v-text="Servicio.num"></td>
                                    <td v-text="Servicio.nombre"></td>
                                    <td v-text="Servicio.descripcion"></td>
                                    <td v-text="Servicio.telefono"></td>
                                    <td v-text="Servicio.receptor"></td>
                                    <td v-text="Servicio.monto"></td>


                   <td>
                                      <div v-if="Servicio.estado=='EnProceso'">
                                           <span class="badge badge-warning"> En Proceso</span>
                                        </div>
                                      <div v-if="Servicio.estado=='Terminado'">
                                           <span class="badge badge-info"> Terminado </span>
                                      </div>
                                       <div v-if="Servicio.estado=='Entregado'">
                                           <span class="badge badge-success"> Entregado </span>
                                      </div>
                   </td>

                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="clearfix card-footer">
                 <nav>
                <ul class="float-right m-0 pagination pagination-sm">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>






<div tabindex="-1" class="p-5 modal fade" :class="{'mostrar' : modal}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">

                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Nombre:</label>
                     <input type="text" class="form-control"   v-model="nombre">
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Descripcion:</label>
                      <input type="text" class="form-control"  v-model="descripcion" >
                  </div>
                 <div class="form-group row">
                     <label for="message-text" class="col-form-label">Telefono:</label>
                      <input type="text" class="form-control"  v-model="telefono" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Receptor:</label>
                      <input type="text" class="form-control"  v-model="receptor" >
                  </div>


                   <div v-show="errorServicio" class="form-growp row div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjServicio" :key="error" v-text="error">
                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row div-error">
                    <p class="text-center text-error">La Servicio ya Existe!</p>
                  </div>
                  </form>
                 </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
              <button type="button" v-if="tipoAccion==1" :disabled = "deshabilitar_boton==1"  class="btn btn-primary" @click="registrarServicio()">Guardar</button>
               <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizarServicio()">Actualizar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




      <!-- /.row -->
    </div><!-- /.container-fluid -->

  <!-- /.content -->
</template>
<script>




export default {

       data (){
            return {
                Servicio_id: 0,
                 nombre : '',
                descripcion : '',
                telefono: '',
                monto:'0.00',
                receptor:'',
                arrayServicio : [],
                modal : 0,
                tituloModal : '',
                tipoAccion : 0,
                 urlhttp: this.$api_url,
                errorServicio : 0,
                errorMostrarMsjServicio : [],
                 'mensaje':'',
                 deshabilitar_boton:0,
                'val1':0,
                    'val2':0,
                    'val3':0,
                    'val4':0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,

                },
                offset : 3,
                criterio : 'nombre',
                buscar : ''
            }
        },




        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;

            }
        },
        methods : {
            listarServicio (page,buscar,criterio){

                let me=this;
                 var url= '/servicio?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio;
                axios.get(url).then(function (response) {
                  //console.log(response);
                    var respuesta= response.data;
                    me.arrayServicio = respuesta.Servicio.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },



            cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarServicio(page,buscar,criterio);
            },



            registrarServicio(){
                if (this.validarServicio()){
                    return;
                }

                let me = this;

                me.deshabilitar_boton=1;

                axios.post('/servicio/registrar',{


                   'nombre': this.nombre,
                    'descripcion': this.descripcion,
                    'telefono': this.telefono,
                    'receptor': this.receptor


                }).then(function (response) {
                  //console.log(response);
                    me.cerrarModal();
                    me.deshabilitar_boton=0;
                    me.listarServicio(1,'','nombre');
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });


                          setTimeout(function(){



                            window.open('/servicio/pdf/'+response.data.id+','+'_blank');


                        }, 500);



                }).catch(function (error) {
                    console.log(error);
                });
                  me.deshabilitar_boton=0;
            },


            actualizarServicio(){

               if (this.validarServicio()){
                    return;
                }

                let me = this;


                axios.put('/servicio/actualizar',{


                    'nombre': this.nombre,
                    'descripcion': this.descripcion,

                    'id': this.Servicio_id


                }).then(function (response) {
                    me.cerrarModal();
                    me.listarServicio(1,'','nombre');
                        Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });

                }).catch(function (error) {
                    console.log(error);
                });

            },

               pdfServicio(id){

                 window.open('/servicio/pdf/'+id+','+'_blank');

            },

              terminado : function(id){
                      let me = this;



  let tienda = me.idlugar2;




                     Swal.fire({
                    title: 'Terminado',
                     html: `
                     <div class="form-group">
                     <label>Monto</label>
                     <input type="number" id="monto" class="swal2-input" >
                     </div>
                      <div class="form-group">
                      <label>Tecnico</label>
                    <input type="text" id="tecnico" class="swal2-input" placeholder="Tecnico">
                    </div>
                     <div class="form-group">
                     <label>Servicio</label>
                    <input type="text" id="servicio" class="swal2-input" placeholder="Servicio">
                    </div>
                      `,
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        const monto = Swal.getPopup().querySelector('#monto').value
                        const tecnico = Swal.getPopup().querySelector('#tecnico').value
                        const servicio = Swal.getPopup().querySelector('#servicio').value

                 if (!monto || !tecnico || !servicio) {
      Swal.showValidationMessage(`Ingrese Los Campos Vacios`)
                 }else{

                axios.post('/servicio/terminado',{

                     'id': id,
                    'monto' : monto,
                    'servicio': servicio,
                    'tecnico': tecnico,
                    'lugar' : tienda


                }).then(function (response) {
                  console.log(response);
                    me.listarServicio(1,'','nombre',me.idlugar2);



                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500

                        });




                }).catch(function (error) {
                    console.log(error);
                });

                             }


                    },
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                        title: `${result.value.login}'s avatar`,
                        imageUrl: result.value.avatar_url
                        })
                    }
                    })

              },



                  entregado : function(id, monto){

                     let me = this;
              Swal.fire({
                title: 'Esta seguro/a de la Entrega de Este Servicio?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/servicio/entregado',{
                          'id': id,
                          'monto': monto
                        }).then(function(response){
                          me.listarServicio(1,'','nombre',me.idlugar2);
                          Swal.fire(
                            'Entregado!',
                            'El Servicio a Cambiado a Estado Entregado.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })
                },


           duplicado(){
             console.log(this.nombre);
               axios.put('/servicio/validar',{



                    'nombre': this.nombre

                }).then(response => {
                  this.mensaje = response.data;
                  console.log(this.mensaje);
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },



             validarServicio(){



                  this.errorServicio=0;
                this.errorMostrarMsjServicio  =[];



                if (!this.nombre){
                   this.errorMostrarMsjServicio.push("El nombre no puede estar vacio.");

                }




                if (this.errorMostrarMsjServicio.length) this.errorServicio = 1;

                return this.errorServicio;






            },
            cerrarModal(){
                this.modal=0;
                this.tituloModal='';
                this.nit='';
                this.nombre='';
                this.direccion='';
                this.telefono='';
                this.errorServicio=0;
                this.val1=0;
                this.val2=0;

            },
            abrirModal(modelo, accion, data = []){



                switch(modelo){
                    case "Servicio":
                    {
                        switch(accion){
                            case 'registrar':
                            {
                               this.modal = 1;
                                this.tituloModal = 'Registrar Servicio';
                                this.nombre='';
                                this.descripcion='';
                                this.tipoAccion = 1;
                                break;
                            }
                            case 'actualizar':
                            {
                                //console.log(data);
                                this.modal=1;
                                this.tipoAccion=2;
                                this.tituloModal='Actualizar Servicio';
                                this.Servicio_id=data['id'];
                                this.nombre = data['nombre'];
                                this.descripcion = data['descripcion'];


                                break;
                            }
                        }
                    }
                }

            },

           desactivarServicio : function(id){
              Swal.fire({
                title: 'Esta seguro/a que desea desactivar el Servicio?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/servicio/desactivar',{
                          'id': id
                        }).then(function(response){
                          me.listarServicio(1,'','nombre');
                          Swal.fire(
                            'Desactivado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })
                },


                selectLugar2(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar2 = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },


               activarServicio: function(id){

                Swal.fire({
                title: 'Esta seguro/a que desea activar Servicio?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/servicio/activar',{
                          'id': id
                        }).then(function(response){
                         me.listarServicio(1,'','nombre');
                          Swal.fire(
                            'activado!',
                            'El registro a sido activado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })


              },

                    verServicio: function(id){

                      let me=this;

                    let servicio="";
                    let tecnico="";

                //Obtener los datos del venta
                var arrayVerServicio=[];
                var url= '/servicioAdmin/ver?id=' + id;

                axios.get(url).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    arrayVerServicio = respuesta.servicio;

                    servicio = arrayVerServicio[0]['servicio'];
                    tecnico=arrayVerServicio[0]['tecnico'];



                Swal.fire({
  title: '<strong>Servicio</strong>',

  html:
    ' <b> Tecnico</b> ' +
     '<br>' +
    tecnico +
     '<br>' +
    '<b> Servicio</b>' +
     '<br>' +
    servicio,
  showCloseButton: true,
  showCancelButton: true,
  focusConfirm: false,

})


                })
                .catch(function (error) {
                    console.log(error);
                });





                  }


        },
        mounted() {

            this.listarServicio(1,this.buscar,this.criterio);
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>
