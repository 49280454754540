<template>
    <!-- Main content -->    
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Usuarios</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">
       
      <div class="row">
        
<div class="col-12">
            

            <div class="card ">
              <div class="card-header">
                
                 <button type="button" @click="abrirModal('user','registrar')" class="btn btn-success mb-2"><i class="fas fa-plus-square"></i>&nbsp;Nuevo</button>&nbsp;
                 <div class="form-group row">
                   <div class="col-md-6">
                     <div class="input-group">
                       <select name="" id="" class="form-control col-md-3" v-model="criterio">
                         <option value="usuario">Usuario</option>
                         <option value="nombre">Nombre</option>
                       </select>
                       <input type="text" class="form-control" placeholder="Buscar" v-model="buscar" @keyup.enter="listarUser(1,buscar,criterio)">
                       <button type="submit" class="btn btn-primary" @click.prevent="listarUser(1,buscar,criterio)"><i class="fas fa-search"></i>&nbsp;Buscar</button>
                     </div>
                   </div>
                 </div>

               </div>
                
               
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Opciones</th>
                    <th>Tienda</th>
                    <th>Nombre</th>
                    <th>Usuario</th>
                    <th>Rol</th>
                    <th>Estado</th>
                    
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(user,index) in arrayUser" :key="user.id" >
                     <td>
                        {{(index+1)}}
                     </td>
                    <td>
                       <button type="button" @click.prevent="abrirModal('user','actualizar',user)" class="btn btn-warning btn-sm"><i class="fas fa-edit"></i></button>&nbsp;
                      <template v-if="user.condicion">
                        <button type="button" class="btn btn-danger btn-sm" @click.prevent="desactivarUsuario(user.id)">
                          <i class="fas fa-trash-alt"></i>
                          </button> 
                      </template>
                      <template v-else>
                        <button type="button" class="btn btn-info btn-sm" @click.prevent="activarUsuario(user.id)"><i class="far fa-thumbs-up"></i></button> 
                      </template>
                      
                    </td>
                                    <td v-if="user.idrol == 1" >Administracion</td>
                                    <td v-else v-text="user.nombre_lugar"></td>
                                    <td v-text="user.nombre"></td>
                                    <td v-text="user.usuario"></td>
                                    <td v-text="user.nombre_rol"></td>
                   <td>
                       <div v-if="user.condicion">
                            <span class="badge badge-success"> Activo </span>
                       </div>
                       <div v-else>
                            <span class="badge badge-danger"> Desactivado </span>
                       </div>
                      
                   </td>
                 
                  </tr>
                 
                  
                  </tbody>
                  <tfoot>
                  
                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>   
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>






<div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal"></h4>
              <button type="button" class="close" @click.prevent="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                    <div class="form-group ">
                      <label class="col-md-3 form-control-label" >Rol </label>
                          <div class="col-md-9">
                          <select class="form-control"  v-model="idrol">
                              <option value="0">Seleccione un rol</option>
                              <option v-for="rol in arrayRol" :key="rol.id" :value="rol.id" v-text="rol.nombre"></option>
                            </select>
                          </div>
                    </div>
                    <div v-if="idrol!=1">

                      <div class="form-group ">
                      <label class="col-md-3 form-control-label" >Tienda </label>
                          <div class="col-md-9">
                          <select class="form-control"  v-model="idlugar">
                              <option value="0">Seleccione una Tienda</option>
                              <option v-for="lugar in arrayLugar" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                            </select>
                          </div>
                    </div>


                   </div>
                     

                  <div class="form-group ">
                      <label for="recipient-name" class="col-form-label">Nombre:</label> 
                      <input type="text" class="form-control"  v-model="nombre">
                    </div>
                    
                    <div class="form-group ">
                      <label for="message-text" class="col-form-label">Usuario:</label>
                        <input type="text" class="form-control" @blur="duplicado()"  v-model="usuario" >
                    </div>
                    
                    <div class="form-group ">
                      <label for="message-text" class="col-form-label">Password:</label>
                        <input type="text" class="form-control"  v-model="password" >
                    </div>
                    
                    
                  
                    <div v-show="errorUser" class="form-growp   div-error">
                        <div class="text-center text-error">
                                          <div v-for="error in errorMostrarMsjUser" :key="error" v-text="error">

                                          </div>
                                      </div>
                    </div>
                    <div v-show="deshabilitar_boton" class="form-growp   div-error">
                      <p class="text-center text-error">El Usuario ya Existe!</p>
                    </div> 
                    </form>
                </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click.prevent="cerrarModal()">Cerrar</button>
              <button type="button" :disabled = "deshabilitar_boton==1" v-if="tipoAccion==1" class="btn btn-primary" @click="registrarUser()">Guardar</button>
               <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click.prevent="actualizarUser()">Actualizar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->
    


 
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  
  <!-- /.content -->
</template>
<script>




export default {
  
       data (){
            return {
                user_id: 0,
                nombre : '',
                usuario : '',
                password : '',
                idrol : 0,
                idlugar : 0,
                arrayUser : [],
                arrayRol : [],
                arrayLugar : [],
                modal : 0,
                tituloModal : '',
                tipoAccion : 0,
                errorUser : 0,
                deshabilitar_boton:0,
                errorMostrarMsjUser : [],
                'mensaje':'',
                
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                    
                },
                offset : 3,
                criterio : 'nombre',
                buscar : ''
            }
        },
        

  

        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }
                
                var from = this.pagination.current_page - this.offset; 
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2); 
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }  

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;             

            }
        },
        methods : { 
            listarUser (page,buscar,criterio){
            
                let me=this;
                 var url= '/user?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio;
                axios.get(url).then(function (response) {
                  //console.log(response);
                    var respuesta= response.data;
                    me.arrayUser = respuesta.usuarios.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });

                
            },

            selectRol(){
                let me=this;
                var url= '/rol/selectRol';
                axios.get(url).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    me.arrayRol = respuesta.roles;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarUser(page,buscar,criterio);
            },



            registrarUser(){

                if (this.validarUser()){
                    return;
                }


                
           
                let me = this;

              

                 me.deshabilitar_boton=1;


                 if(this.idrol==1){
                   axios.post('/user/registrar',{
                  
                  
                    'nombre': this.nombre,
                    'usuario': this.usuario,
                    'password': this.password,
                    'idrol' : this.idrol,
                    'idlugar' : 1
                  
                }).then(function (response) {
                 // console.log(response);
                    me.cerrarModal();
                    me.deshabilitar_boton=0;
                    me.listarUser(1,'','nombre');
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                }).catch(function (error) {
                    console.log(error);
                });

                }else{


                   axios.post('/user/registrar',{
                  
                  
                    'nombre': this.nombre,
                    'usuario': this.usuario,
                    'password': this.password,
                    'idrol' : this.idrol,
                    'idlugar' : this.idlugar
                  
                }).then(function (response) {
                 // console.log(response);
                    me.cerrarModal();
                    me.deshabilitar_boton=0;
                    me.listarUser(1,'','nombre');
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                }).catch(function (error) {
                    console.log(error);
                });


                }
               
            },


            actualizarUser(){
              
               if (this.validarUser()){
                    return;
                }


                  if(this.idrol==1){
                  this.idrol==1;
                }
                
                let me = this;
                
                   
                axios.put('/user/actualizar',{
                    
                  
                    'nombre': this.nombre,
                    'usuario': this.usuario,
                    'password': this.password,
                    'idrol' : this.idrol,
                    'idlugar' : this.idlugar,
                  
                    'id': this.user_id

                    
                }).then(function (response) {
                    me.cerrarModal();
                    me.listarUser(1,'','nombre');
                        Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                    
                }).catch(function (error) {
                    console.log(error);
                }); 
                
            },


            duplicado(){

               axios.put('/user/validar',{
                  
                    'usuario': this.usuario
                    
                }).then(response => {
                  this.mensaje = response.data;
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },
          

             validarUser(){

             
                this.errorUser=0;
                this.errorMostrarMsjUser  =[];

                if(!this.idrol==1){

                   if (!this.nombre){
                  this.errorMostrarMsjUser .push("El nombre no puede estar vacío.");
                  
                }

                if (!this.usuario){
                   this.errorMostrarMsjUser.push("El nombre del Usuario no puede estar vacio.");
                  
                } 
                

                if (!this.password){
                  this.errorMostrarMsjUser.push("El password no puede estar vacío.");
                  
                }
                
                if (this.idrol==0){
                  this.errorMostrarMsjUser.push("Seleccione un Rol.");
                  
                }

                if (this.idlugar==0){
                  this.errorMostrarMsjUser.push("Seleccione una Tienda.");
                  
                }

                if (this.errorMostrarMsjUser.length) this.errorUser = 1;
               
              
                return this.errorUser;

                }else{

                  if (!this.nombre){
                  this.errorMostrarMsjUser .push("El nombre no puede estar vacío.");
                  
                }

                if (!this.usuario){
                   this.errorMostrarMsjUser.push("El nombre del Usuario no puede estar vacio.");
                  
                } 
                

                if (!this.password){
                  this.errorMostrarMsjUser.push("El password no puede estar vacío.");
                  
                }
                
                if (this.idrol==0){
                  this.errorMostrarMsjUser.push("Seleccione un Rol.");
                  
                }


                if (this.errorMostrarMsjUser.length) this.errorUser = 1;
               
              
                return this.errorUser;

                }

               
               
              
            },


            cerrarModal(){
                this.modal=0;
                this.tituloModal='';
                this.nombre='';
                this.usuario='';
                this.password='';
                this.idrol=0;
                this.idlugar=0;
                this.errorUser=0;
               this. deshabilitar_boton=0;
            },
            abrirModal(modelo, accion, data = []){

              this.selectRol();
              this.selectLugar();

                switch(modelo){
                    case "user":
                    {
                        switch(accion){
                            case 'registrar':
                            {
                               this.modal = 1;
                                this.tituloModal = 'Registrar Usuario';
                                this.nombre='';
                                this.usuario='';
                                this.password='';
                                this.idrol=0;
                                this.idlugar=0;
                                this.tipoAccion = 1;
                                break;
                            }
                            case 'actualizar':
                            {
                                //console.log(data);
                                this.modal=1;
                                this.tipoAccion=2;
                                this.tituloModal='Actualizar Usuario';
                                this.user_id=data['id'];
                                this.nombre = data['nombre'];
                                this.usuario = data['usuario'];
                                this.password = data['password'];
                                this.idrol = data['idrol'];
                                this.idlugar = data['idlugar'];
                                break;
                            }
                        }
                    }
                }
              
            },
           
           desactivarUsuario : function(id){
              Swal.fire({
                title: 'Esta seguro/a que desea desactivar el Usuario?',
                
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/user/desactivar',{
                          'id': id
                        }).then(function(response){
                          me.listarUser(1,'','nombre');
                          Swal.fire(
                            'Desactivado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });

                  
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                 
                }
              })
                },
            

               activarUsuario: function(id){

                Swal.fire({
                title: 'Esta seguro/a que desea activar Usuario?',
                
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/user/activar',{
                          'id': id
                        }).then(function(response){
                         me.listarUser(1,'','nombre');
                          Swal.fire(
                            'activado!',
                            'El registro a sido activado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });

                  
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                 
                }
              })


              }

           
        },
        mounted() {
            this.listarUser(1,this.buscar,this.criterio);
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>