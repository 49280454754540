<template>
            <main class="main">
            <!-- Content Header (Page header) -->
            <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark">Entregadas/liquidadas</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">

                </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
            </div>
            <div class="container-fluid">
                <!-- Ejemplo de tabla Listado -->
                <div class="card">

                    <!------------------------------------------------- Listado Principal ------------------------------>
                    <template v-if="listado==1">
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-6">

                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tienda</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar2">
                                    <option value="0" disabled>Seleccione</option>
                                            <option v-for="lugar in arrayLugar2" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                                    </select>
                                 </div>

                                <div class="input-group">
                                    <select class="form-control col-md-3" v-model="criterio">
                                      <option value="tipo_comprobante">Tipo Comprobante</option>
                                      <option value="num_comprobante">Número Comprobante</option>
                                      <option value="fecha_hora">Fecha-Hora</option>
                                    </select>
                                    <input type="text" v-model="buscar" @keyup.enter="listarTracking(1,buscar,criterio,idlugar2)" class="form-control" placeholder="Texto a buscar">
                                    <button type="submit" @click.prevent="listarTracking(1,buscar,criterio,idlugar2)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                </div>

                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th>Opciones</th>
                                        <th>Usuario</th>
                                        <th>Cliente</th>
                                        <th>Tipo Comprobante</th>
                                         <th>Datos de Banco</th>
                                        <th>Número Comprobante</th>
                                        <th>Fecha Hora</th>
                                        <th>Envio</th>
                                         <th>Total Guia</th>
                                        <th>Total_neto</th>

                                        <th>TotalCosto</th>
                                        <th>Utilidad</th>

                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="tracking in arrayTracking" :key="tracking.id">
                                        <td>
                                            <button type="button" @click.prevent="verTracking(tracking.id)" class="btn btn-success btn-sm">
                                            <i class="fas fa-eye"></i>
                                            </button> &nbsp;
                                            <template v-if='tracking.estado=="Entregada"'>
                                            <button type="button" @click.prevent="pagadaTracking(tracking.id)" class="btn btn-primary btn-sm">
                                            <i class="fas fa-check-circle"></i>
                                            </button> &nbsp;
                                            </template>

                                        </td>
                                        <td v-text="tracking.usuario"></td>
                                        <td v-text="tracking.nombre"></td>
                                        <td v-text="tracking.tipo_comprobante"></td>
                                         <td v-text="tracking.databan"></td>
                                        <td><a :href="'https://www.cargoexpreso.com/tracking/?guia='+tracking.num_comprobante"  v-text="tracking.num_comprobante"></a></td>
                                        <td v-text="tracking.fecha_hora"></td>
                                        <td v-text="tracking.envio"></td>
                                        <td v-text="tracking.total"></td>
                                        <td v-text="tracking.total_neto"></td>

                                        <td v-text="tracking.totalc"></td>
                                        <td v-text="tracking.totalu"></td>



                                        <td>

                                        <div v-if="tracking.estado=='Entregada'">
                                           <span class="badge badge-warning"> Entregada </span>
                                        </div>


                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav>
                            <ul class="pagination">
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                                </li>
                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    </template>
                    <!--Fin Listado-->

                <!------------------------------------------------- Detalle ------------------------------>
                    <template v-else-if="listado==0">
                    <div class="card-body">
                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Cliente <span style="color:red;" v-show="idcliente==0">(*Seleccione)</span></label>
                                    <div class="form-inline">

                                        <button @click.prevent="abrirModalB()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                        <input type="text" readonly class="form-control" v-model="nit">
                                        <input type="text" readonly class="form-control" v-model="nombrep">
                                        <input type="text" readonly class="form-control" v-model="direccion">
                                        <input type="text" readonly class="form-control" v-model="telefono">
                                    </div>
                              </div>
                            </div>

                            <div class="col-md-4">

                                <div class="form-group">
                                    <label>Tipo Comprobante(*)</label>
                                    <select class="form-control" v-model="tipo_comprobante">
                                        <option value="0">Seleccione</option>
                                        <option value="COD">COD</option>
                                        <option value="SE">SE</option>
                                        <option value="COLLET">COLLET</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Número Comprobante(*)</label>
                                    <input type="text" class="form-control" v-model="num_comprobante" placeholder="000xx">
                                </div>
                            </div>
                             <div class="col-md-4">
                                <div class="form-group">
                                    <label for="">Envio(*)</label>
                                    <input type="number" class="form-control" v-model="envio">
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div v-show="errorTracking" class="form-group row div-error">
                                    <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjTracking" :key="error" v-text="error">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Producto <span style="color:red;" v-show="idproducto==0">(*Seleccione)</span></label>
                                    <div class="form-inline">
                                        <button @click.prevent="abrirModal()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="form-group row border">
                            <div class="table-responsive col-md-12">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Producto</th>
                                            <th id="costo">Costo</th>
                                            <th>Precio</th>
                                            <th>Cantidad</th>
                                            <th>Descuento</th>
                                            <th>Subtotal</th>
                                            <th id="totalc">SubtotalCosto</th>
                                            <th id="totalu">SubtotalUtilidad</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                            <td>
                                                <button @click.prevent="eliminarDetalle(index)" type="button" class="btn btn-danger btn-sm">
                                                    <i class="fas fa-times-circle"></i>
                                                </button>
                                            </td>
                                            <td v-text="detalle.producto">
                                            </td>
                                            <td>
                                                <input style="width: 100px" disabled v-model="detalle.costo" type="number" value="4" class="form-control" >
                                            </td>

                                            <td>

                                                <input style="width: 100px" v-model="detalle.precio" type="number" value="3" class="form-control" >
                                            </td>
                                            <td>
                                                 <span style="color:red;" v-show="(detalle.stock < detalle.cantidad)">Cantidad Mayor al Stock</span>
                                                <input v-model.number="detalle.cantidad"  type="number" value="2" class="form-control">
                                            </td>
                                            <td>
                                                <span style="color:red;" v-show="detalle.descuento>(detalle.precio*detalle.cantidad)">Descuento superior</span>
                                                <input v-model="detalle.descuento" type="number" class="form-control">
                                            </td>
                                            <td>
                                                {{(detalle.precio*detalle.cantidad-detalle.descuento).toFixed(2)}}
                                            </td>
                                             <td id="totalc2">
                                                {{(detalle.costo*detalle.cantidad).toFixed(2)}}
                                            </td>
                                             <td id="totalu2">
                                                {{((detalle.precio*detalle.cantidad-detalle.descuento)-(detalle.costo*detalle.cantidad)).toFixed(2)}}
                                            </td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total Parcial:</strong></td>
                                            <td>Q {{totalParcial=calcularTotal}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total Envio:</strong></td>
                                            <td>Q {{totalEnvio=parseFloat(envio).toFixed(2)}}</td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{total= (parseFloat(this.calcularTotal) + parseFloat(this.envio)).toFixed(2)}}</td>
                                        </tr>
                                        <tr  style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr  style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>TotalUtilidad2:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <button type="button" @click.prevent="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>
                                <button type="button" class="btn btn-primary" @click.prevent="registrarTracking()">Registrar Tracking</button>
                            </div>
                        </div>
                    </div>
                    </template>
                    <!-- Fin Detalle-->

                    <!------------------------------------------------- Ver Tracking ------------------------------>
                    <template v-else-if="listado==2">
                    <div class="card-body">
                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="">Cliente</label>
                                    <p v-text="cliente"></p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo Comprobante</label>
                                    <p v-text="tipo_comprobante"></p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Número Comprobante</label>
                                    <p v-text="num_comprobante"></p>
                                </div>
                            </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                    <label>Envio</label>
                                    <p v-text="envio"></p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Total Articulos</label>
                                    <p>{{totalArticulo}}</p>
                                </div>
                            </div>

                        </div>
                        <div class="form-group row border">
                            <div class="table-responsive col-md-12">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                              <th>No</th>
                                            <th>Codigo</th>

                                            <th>Genero</th>

                                            <th>Producto</th>
                                            <th>Costo</th>
                                            <th>Precio</th>
                                            <th>Garantia</th>
                                            <th>Cantidad</th>
                                            <!--
                                            <th>Descuento</th>
                                            -->
                                            <th>Subtotal</th>
                                            <th>SubtotalCosto</th>
                                            <th>SubtotalUlitidad</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                            <td>
                                                {{(index+1)}}
                                            </td>

                                            <td v-text="detalle.codigo">
                                            </td>

                                           <td v-text="detalle.genero">
                                            </td>


                                            <td v-text="detalle.articulo">
                                            </td>

                                            <td v-text="detalle.costo">
                                            </td>
                                            <td v-text="detalle.precio">
                                            </td>
                                             <td v-text="detalle.garantia">
                                            </td>
                                            <td v-text="detalle.cantidad">
                                            </td>
                                            <!--
                                            <td v-text="detalle.descuento">
                                            </td>
                                            -->
                                            <td>
                                                {{detalle.precio*detalle.cantidad-detalle.descuento}}
                                            </td>
                                            <td>
                                                {{detalle.costo*detalle.cantidad}}
                                            </td>
                                            <td>
                                                {{(detalle.precio*detalle.cantidad-detalle.descuento)-(detalle.costo*detalle.cantidad)}}
                                            </td>
                                        </tr>


                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>Total Parcial:</strong></td>
                                            <td>Q {{totalParcial=calcularTotal}}</td>
                                        </tr>
                                        <!--
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Impuesto:</strong></td>
                                            <td>Q {{totalImpuesto=((total*impuesto)).toFixed(2)}}</td>
                                        </tr>
                                    -->
                                    <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>Total Envio:</strong></td>
                                            <td>Q {{totalEnvio=envio}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>Total Neto:</strong></td>
                                            <td >Q <span id="totalTrackingArticulo">{{total=(parseFloat(totalParcial)+parseFloat(totalEnvio)).toFixed(2)}}</span> </td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>TotalUtilidad:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>



                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="4">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <button type="button" @click.prevent="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>

                            </div>
                        </div>
                    </div>
                    </template>
                    <!-- fin ver tracking -->
                </div>
                <!-- Fin ejemplo de tabla Listado -->
            </div>

   <!------------------------------------------------- Modal agregar ------------------------------>

            <div class="modal fade" tabindex="-1" :class="{'mostrar' : modal}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModal"></h4>
                            <button type="button" class="close" @click.prevent="cerrarModal()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <select class="form-control col-md-3" v-model="criterioA">
                                        <option value="nombre">Nombre</option>
                                        <option value="descripcion">Descripción</option>

                                        </select>
                                        <input type="text" v-model="buscarA" @keyup.enter="listarProducto(buscarA,criterioA)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click.prevent="listarProducto(buscarA,criterioA)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Nombre</th>
                                            <th>Categoría</th>
                                            <th>Precio Tracking</th>
                                            <th>Stock</th>
                                            <th>Estado</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="producto in arrayProducto" :key="producto.id">
                                            <td>
                                                <button type="button" @click.prevent="agregarDetalleModal(producto)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                            </td>

                                            <td v-text="producto.nombre"></td>
                                            <td v-text="producto.nombre_categoria"></td>
                                            <td v-text="producto.precio_venta"></td>
                                            <td v-text="producto.stock"></td>
                                            <td>
                                                <div v-if="producto.condicion">
                                                    <span class="badge badge-success">Activo</span>
                                                </div>
                                                <div v-else>
                                                    <span class="badge badge-danger">Desactivado</span>
                                                </div>

                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click.prevent="cerrarModal()">Cerrar</button>

                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->


             <!------------------------------------------------- Modal Cliente ------------------------------>

            <div class="modal fade" tabindex="-1" :class="{'mostrar' : modalB}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModalB"></h4>
                            <button type="button" class="close" @click.prevent="cerrarModalB()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <select class="form-control col-md-3" v-model="criterioB">
                                        <option value="nit">Nit</option>
                                        <option value="nombre">Nombre</option>

                                        </select>
                                        <input type="text" v-model="buscarB" @keyup.enter="listarCliente(buscarB,criterioB)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click.prevent="listarCliente(buscarB,criterioB)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Nit</th>
                                            <th>Nombre</th>
                                            <th>Direccion</th>
                                            <th>Telefono</th>
                                             <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cliente in arrayCliente" :key="cliente.id">
                                            <td>
                                                <button type="button" @click.prevent="agregarClienteModal(cliente)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                            </td>

                                            <td v-text="cliente.nit"></td>
                                            <td v-text="cliente.nombre"></td>
                                            <td v-text="cliente.direccion"></td>
                                            <td v-text="cliente.telefono"></td>
                                            <td>
                                                <div v-if="cliente.condicion">
                                                    <span class="badge badge-success">Activo</span>
                                                </div>
                                                <div v-else>
                                                    <span class="badge badge-danger">Desactivado</span>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click.prevent="cerrarModalB()">Cerrar</button>
                            <button type="button" v-if="tipoAccion==1" class="btn btn-primary" @click.prevent="registrarCliente()">Guardar</button>
                            <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click.prevent="actualizarCliente()">Actualizar</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->
        </main>
</template>

<script>

    export default {
        data (){
            return {
                tracking_id: 0,
                idcliente:0,
                idlugar:0,
                idlugar2:1,
                nit: '',
                cliente:'',
                nombre : '',
                nombrep : '',
                direccion : '',
                telefono : '',
                urlhttp: this.$api_url,
                tipo_comprobante : 'COD',
                serie_comprobante : '',
                num_comprobante : '',
                envio: '',
                total:0.0,
                totalEnvio: 0.0,
                totalParcial: 0.0,
                arrayTracking : [],
                arrayCliente: [],
                arrayDetalle : [],
                arrayLugar : [],
                 arrayLugar2 : [],
                listado:1,
                modal : 0,
                modalB : 0,
                tituloModal : '',
                tituloModalB : '',
                tipoAccion : 0,
                errorTracking : 0,
                errorMostrarMsjTracking : [],
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset : 3,
                criterio : 'num_comprobante',
                buscar : '',
                criterioA:'nombre',
                buscarA: '',
                criterioB:'nombre',
                buscarB: '',
                arrayProducto: [],
                idproducto: 0,
               // codigo: '',
                producto: '',
                precio: 0,
                cantidad:0,
                descuento: 0,
                stock: 0
            }
        },
        components: {

        },
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            },


            calcularTotal: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad-this.arrayDetalle[i].descuento)
                }
                return resultado.toFixed(2);
            },


            calcularTotalc: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },


            calcularTotalu: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+((this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad-this.arrayDetalle[i].descuento)-(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad))
                }
                return resultado.toFixed(2);
            },

             totalMasEnvio: function(){

                var resultado=0.0;
                resultado= (this.calcularTotal) + (this.envio);
                return resultado.toFixed(2);

            },


            totalArticulo: function(){
                var result=0;
                 for(var i=0;i<this.arrayDetalle.length;i++){

                    result+=(this.arrayDetalle[i].cantidad)
                }
                return result;


            },

            contarAlerta: function(){

              var resultado=0;
                for(var i=0;i<this.arrayProducto.length;i++){

                    if(this.arrayProducto[i].stock <= this.arrayProducto[i].minimo){

                      resultado +=1;
                    }


                }
                return resultado;
            }
        },
        methods : {


      /**************************************************************************************** */
            listarTracking (page,buscar,criterio,idlugar2){
                let me=this;
                var url= '/tracking/entregado?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio + '&idlugar2='+ idlugar2;
                axios.get(url).then(function (response) {
                    console.log(response);
                    var respuesta= response.data;
                    me.arrayTracking = respuesta.trackings.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

       /**************************************************************************************** */
            buscarCliente(){
                let me=this;


                var url= '/cliente/selectCliente?filtro='+search;
                axios.get(url).then(function (response) {
                    let respuesta = response.data;
                    me.arrayCliente=respuesta.clientees;

                })
                .catch(function (error) {
                    console.log(error);
                });
            },

        /**************************************************************************************** */
            getDatosCliente(val1){
                let me = this;
                me.loading = true;
                me.idcliente = val1.id;
            },

         /**************************************************************************************** */
            buscarProducto(){
                let me=this;
                var url= '/producto/buscarProducto?filtro=' + me.nombre;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos;

                    if (me.arrayProducto.length>0){
                        me.producto=me.arrayProducto[0]['nombre'];
                        me.idproducto=me.arrayProducto[0]['id'];
                    }
                    else{
                        me.producto='No existe producto';
                        me.idproducto=0;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

          selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            selectLugar2(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar2 = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },


          /**************************************************************************************** */

            cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarTracking(page,buscar,criterio,idlugar2);
            },

          /**************************************************************************************** */
            encuentra(id){
                var sw=0;
                for(var i=0;i<this.arrayDetalle.length;i++){
                    if(this.arrayDetalle[i].idproducto==id){
                        sw=true;
                    }
                }
                return sw;
            },

          /**************************************************************************************** */
            eliminarDetalle(index){
                let me = this;
                me.arrayDetalle.splice(index, 1);
            },
           /**************************************************************************************** */
            agregarDetalle(){
                let me=this;
                if(me.idproducto==0 || me.cantidad==0 || me.precio==0){
                }
                else{
                    if(me.encuentra(me.idproducto)){
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: me.idproducto,
                            producto: me.producto,
                            cantidad: me.cantidad,
                            precio: me.precio,
                            descuento: me.descuento,
                            stock: me.stock
                        });
                        me.codigo="";
                        me.idproducto=0;
                        me.producto="";
                        me.cantidad=0;
                        me.precio=0;
                        me.descuento=0;
                        me.stock=0
                    }

                }



            },

             /**************************************************************************************** */
            agregarDetalleModal(data =[]){

                let me=this;
                if(me.encuentra(data['id'])){
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: data['id'],
                            producto: data['nombre'],
                            cantidad: 1,
                            precio: data['precio_venta'],
                            descuento:0,
                            stock:data['stock']

                        });
                    }
            },

            /**************************************************************************************** */
            agregarClienteModal(data =[]){


                let me=this;


                                me.idcliente=data['id'];
                                me.nit = data['nit'];
                                me.nombrep = data['nombre'];
                                me.direccion = data['direccion'];
                                me.telefono = data['telefono'];
                this.cerrarModalB();

            },
             /**************************************************************************************** */
            listarProducto (buscarB,criterioB){
                let me=this;
                var url= '/producto/listarProducto?buscar='+ buscarB + '&criterio='+ criterioB;
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },


            pdfTracking(id){
                 window.open(this.urlhttp+'/tracking/pdf/'+id+','+'_blank');
            },


                 /**************************************************************************************** */
            listarCliente (buscarB,criterioB){
                 let me=this;
                var url= '/cliente/listarCliente?buscarB='+ buscarB + '&criterioB='+ criterioB;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayCliente = respuesta.cliente.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
             /**************************************************************************************** */

            registrarTracking(){
                if (this.validarTracking()){
                    return;
                }

                let me = this;

                axios.post('/tracking/registrar',{
                    'idcliente': this.idcliente,
                    'tipo_comprobante': this.tipo_comprobante,
                    'num_comprobante' : this.num_comprobante,
                    'envio' : this.envio,
                    'total' : this.total,
                    'data': this.arrayDetalle

                }).then(function (response) {
                    me.listado=1;
                    me.listarTracking(1,'','num_comprobante',me.idlugar2);
                    me.idcliente=0;
                    me.tipo_comprobante='COD';
                    me.num_comprobante='';
                    me.envio=30;
                    me.total=0.0;
                    me.idproducto=0;
                    me.producto='';
                    me.cantidad=0;
                    me.precio=0;
                    me.descuento=0;
                    me.arrayDetalle=[];

                     me.cerrarModal();
                    me.listarTracking(1,'','tipo_comprobante',idlugar2);
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500

                        });
/*
                    setTimeout(function(){
                        window.open('http://localhost:8000/tracking/pdf/'+response.data.id+','+'_blank');
                     }, 1000);
      */

                }).catch(function (error) {
                    console.log(error);
                });
            },

             /**************************************************************************************** */
            validarTracking(){
                this.errorTracking=0;
                this.errorMostrarMsjTracking =[];

                if (this.idcliente==0) this.errorMostrarMsjTracking.push("Seleccione un Cliente");
                if (this.tipo_comprobante==0) this.errorMostrarMsjTracking.push("Seleccione el comprobante");
                if (!this.num_comprobante) this.errorMostrarMsjTracking.push("Ingrese el número de comprobante");
                if (!this.envio) this.errorMostrarMsjTracking.push("Ingrese el envio de tracking");
                if (this.arrayDetalle.length<=0) this.errorMostrarMsjTracking.push("Ingrese detalles");

                if (this.errorMostrarMsjTracking.length) this.errorTracking = 1;

                return this.errorTracking;
            },

             /**************************************************************************************** */
            mostrarDetalle(){
                let me=this;
                me.listado=0;

                me.idcliente=0;
                me.tipo_comprobante='COD';
                me.num_comprobante='';
                me.envio=0.0;
                me.total=0.0;
                me.idproducto=0;
                me.producto='';
                me.cantidad=0;
                me.precio=0;
                me.arrayDetalle=[];
            },

             /**************************************************************************************** */
            ocultarDetalle(){
                this.listado=1;
            },

             /**************************************************************************************** */
            verTracking(id){
                let me=this;
                me.listado=2;

                //Obtener los datos del tracking
                var arrayTrackingT=[];
                var url= '/tracking/obtenerCabecera?id=' + id;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    arrayTrackingT = respuesta.tracking;

                    me.cliente = arrayTrackingT[0]['nombre'];
                    me.tipo_comprobante=arrayTrackingT[0]['tipo_comprobante'];
                    me.num_comprobante=arrayTrackingT[0]['num_comprobante'];
                    me.envio=arrayTrackingT[0]['envio'];
                    me.total=arrayTrackingT[0]['total'];

                })
                .catch(function (error) {
                    console.log(error);
                });

                //Obtener los datos de los detalles
                var urld= '/tracking/obtenerDetalles?id=' + id;

                axios.get(urld).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    me.arrayDetalle = respuesta.detalles;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

             /**************************************************************************************** */
            cerrarModal(){
                this.modal=0;
                this.tituloModal='';
            },

             /**************************************************************************************** */
            abrirModal(){
                this.arrayProducto=[];
                this.modal = 1;
                this.tituloModal = 'Seleccione uno o varios productos';
            },

             /**************************************************************************************** */
             /*
            desactivarTracking(id){
               swal.fire({
                title: 'Esta seguro de anular este tracking?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
                }).then((result) => {
                if (result.value) {
                    let me = this;

                    axios.put('/tracking/desactivar',{
                        'id': id
                    }).then(function (response) {
                        me.listarTracking(1,'','tipo_comprobante');
                        swal.fire(
                        'Anulado!',
                        'El tracking ha sido anulado con éxito.',
                        'success'
                        )
                    }).catch(function (error) {
                        console.log(error);
                    });


                } else if (
                    // Read more about handling dismissals
                    result.dismiss === swal.DismissReason.cancel
                ) {

                }
                })
            },
*/
             desactivarTracking : function(id){
              Swal.fire({
                title: 'Esta seguro/a que desea Anular este Tracking?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                  var arrayTrackingT=[];
                        axios.put('/tracking/desactivar',{
                          'id': id


                        }).then(function(response){
                          me.listarTracking(1,'','tipo_comprobante',idlugar2);
                          Swal.fire(
                            'Anulado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })
                },

         /**************************************************************************************** */

        pagadaTracking : function(id){
              Swal.fire({
                title: 'Esta seguro/a que la Guia esta Pagada?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                  var arrayTrackingT=[];
                        axios.put('/tracking/pagada',{
                          'id': id

                        }).then(function(response){
                          me.listarTracking(1,'','tipo_comprobante',me.idlugar2);
                          Swal.fire(
                            'Guia Pagada!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })
                },

         /**************************************************************************************** */



                 cerrarModalB(){
                this.modalB=0;
                this.tituloModalB='';
            },
            abrirModalB(){
                this.arrayCliente=[];
                this.modalB = 1;
                this.tituloModalB = 'Seleccione el cliente';
            },

             mostrarDetalle(){
                let me=this;
                me.listado=0;

                me.idcliente=0;
                me.tipo_comprobante=' ';

                me.num_comprobante='';
                me.envio='';
                me.total=0;
                me.idproducto=0;
                me.producto='';
                me.cantidad=0;
                me.precio=0;
                me.arrayDetalle=[];
            },
            ocultarDetalle(){
                this.listado=1;
            },



        },
        mounted() {
            this.selectLugar2();
            this.listarTracking(1,this.buscar,this.criterio,this.idlugar2);

        }
    }
</script>
<style>
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
    }
    .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }
    @media (min-width: 600px) {
        .btnagregar {
            margin-top: 2rem;
        }
    }

</style>
