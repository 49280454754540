<template>

    <!-- Main content -->
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Notas</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">

          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">

      <div class="row">

<div class="col-12">


            <div class="card ">
              <div class="card-header">

                 <button type="button" @click="abrirModal('nota','registrar')" class="btn btn-success mb-2"><i class="fas fa-plus-square"></i>&nbsp;Nuevo</button>&nbsp;
                 <div class="form-group row">
                   <div class="col-md-6">


                                <div class="input-group">

                                    <select class="form-control col-md-3" v-model="criterio">

                                      <option value="cliente">Cliente</option>
                                      <option value="receptor">Receptor</option>
                                      <option value="fecha_hora">Fecha_hora</option>
                                      <option value="estado">Estado</option>
                                    </select>
                                    <input type="text" v-model="buscar" @keyup.enter="listarnota(1,buscar,criterio)" class="form-control" placeholder="Texto a buscar">
                                    <button type="submit" @click="listarnota(1,buscar,criterio)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                </div>
                            </div>

                 </div>

               </div>


              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Opciones</th>
                    <th>Cliente</th>
                    <th>Fecha_hora</th>
                    <th>Descripcion</th>
                    <th>Telefono</th>
                    <th>Receptor</th>
                    <th>Comentario</th>
                    <th>Estado</th>


                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(nota, index) in arraynota" :key="nota.id" >
                    <td>{{(index + 1)}}</td>
                    <td>




                       <template v-if="nota.estado=='Pendiente'">
                                                <button type="button" class="btn btn-info btn-sm" @click.prevent="atendido(nota.id)">
                                                    <i class="fas fa-check"></i>
                                                </button>
                       </template>
                         <template v-if="nota.estado=='Atendido'">
                                                <button type="button" class="btn btn-success btn-sm" @click.prevent="terminado(nota.id)">
                                                    <i class="fas fa-check"></i>
                                                </button>
                         </template>

                    </td>


                                    <td v-text="nota.nombre"></td>
                                    <td v-text="nota.fecha_hora"></td>
                                    <td v-text="nota.descripcion"></td>
                                    <td v-text="nota.telefono"></td>
                                    <td v-text="nota.receptor"></td>
                                    <td v-text="nota.comentario"></td>



                   <td>
                                      <div v-if="nota.estado=='Pendiente'">
                                           <span class="badge badge-warning"> Pendiente</span>
                                        </div>
                                      <div v-if="nota.estado=='Atendido'">
                                           <span class="badge badge-info"> Atendido </span>
                                      </div>
                                       <div v-if="nota.estado=='Terminado'">
                                           <span class="badge badge-success"> Terminado </span>
                                      </div>

                   </td>

                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>






<div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">

                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Cliente:</label>
                     <input type="text" class="form-control" v-model="nombre">
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Descripcion:</label>
                      <input type="text" class="form-control"  v-model="descripcion" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Telefono:</label>
                      <input type="text" class="form-control"  v-model="telefono" >
                  </div>

                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Receptor:</label>
                      <input type="text" class="form-control"  v-model="receptor" >
                  </div>

                   <div v-show="errornota" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjnota" :key="error" v-text="error">
                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row  div-error">
                    <p class="text-center text-error">El nota ya Existe!</p>
                  </div>
                  </form>
                 </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
              <button type="button" v-if="tipoAccion==1" :disabled = "deshabilitar_boton==1"  class="btn btn-primary" @click="registrarnota()">Guardar</button>
               <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizarnota()">Actualizar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->




      <!-- /.row -->
    </div><!-- /.container-fluid -->

  <!-- /.content -->
</template>
<script>




export default {

       data (){
            return {
                nota_id: 0,
                nombre : '',
                descripcion : '',
                telefono: '',
                monto:'0.00',
                receptor:'',
                arraynota : [],
                arrayLugar2 : [],

                modal : 0,
                 urlhttp: this.$api_url,
                tituloModal : '',
                tipoAccion : 0,
                errornota : 0,
                errorMostrarMsjnota : [],
                 'mensaje':'',
                 deshabilitar_boton:0,
                'val1':0,
                    'val2':0,
                    'val3':0,
                    'val4':0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,

                },
                offset : 3,
                criterio : 'cliente',
                 buscar : ''
            }
        },




        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;

            }
        },
        methods : {

            listarnota(page,buscar,criterio){
           /*
            console.log(buscar);
            console.log(criterio);
            console.log(idlugar2);
            */
                let me=this;
                 var url= '/nota?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio ;
                axios.get(url).then(function (response) {
                  console.log(response);
                    var respuesta= response.data;
                    me.arraynota = respuesta.notificaciones.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },



            cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarnota(page,buscar,criterio);
            },



            registrarnota(){
                if (this.validarnota()){
                    return;
                }

                let me = this;
                 me.deshabilitar_boton=1;

             //    let tienda = me.idlugar2;

               // console.log(this.nombre);

                axios.post('/nota/registrar',{


                    'nombre': this.nombre,
                    'descripcion': this.descripcion,

                    'telefono': this.telefono,
                    'receptor': this.receptor


                }).then(function (response) {
                 // console.log(response);
                    me.cerrarModal();
                    me.deshabilitar_boton=0;
                    me.listarnota(1,'','nombre');
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });


                }).catch(function (error) {
                    console.log(error);
                });
                 me.deshabilitar_boton=0;
            },


            actualizarnota(){

               if (this.validarnota()){
                    return;
                }

                let me = this;


                axios.put('/nota/actualizar',{


                    'nombre': this.nombre,
                    'descripcion': this.descripcion,

                    'id': this.nota_id


                }).then(function (response) {
                    me.cerrarModal();
                    me.listarnota(1,'','nombre');
                        Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });

                }).catch(function (error) {
                    console.log(error);
                });

            },

/*
           duplicado(){
           //  console.log(this.nombre);
               axios.put('/nota/validar',{



                    'nombre': this.nombre

                }).then(response => {
                  this.mensaje = response.data;
                  console.log(this.mensaje);
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },
*/


             validarnota(){


               this.errornota=0;
                this.errorMostrarMsjnota  =[];



                if (!this.nombre){
                   this.errorMostrarMsjnota.push("El Cliente no puede estar vacio.");

                }




                if (this.errorMostrarMsjnota.length) this.errornota = 1;

                return this.errornota;

            },
            cerrarModal(){
                this.modal=0;
                this.tituloModal='';
                this.nit='';
                this.nombre='';
                this.direccion='';
                this.telefono='';
                this.errornota=0;
                this.val1=0;
                this.val2=0;

            },
            abrirModal(modelo, accion, data = []){



                switch(modelo){
                    case "nota":
                    {
                        switch(accion){
                            case 'registrar':
                            {
                               this.modal = 1;
                                this.tituloModal = 'Registrar nota';
                                this.nombre='';
                                this.descripcion='';
                                this.tipoAccion = 1;
                                break;
                            }
                            case 'actualizar':
                            {
                                //console.log(data);
                                this.modal=1;
                                this.tipoAccion=2;
                                this.tituloModal='Actualizar Nota';
                                this.nota_id=data['id'];
                                this.nombre = data['nombre'];
                                this.descripcion = data['descripcion'];


                                break;
                            }
                        }
                    }
                }

            },

           desactivarnota : function(id){
              Swal.fire({
                title: 'Esta seguro/a que desea desactivar el nota?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/nota/desactivar',{
                          'id': id
                        }).then(function(response){
                          me.listarnota(1,'','nombre');
                          Swal.fire(
                            'Desactivado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })
                },



                validarTeminado(){

                },


                pdfnota(id){

                 window.open(this.urlhttp+'/nota/pdf/'+id+','+'_blank');

            },

                  atendido : function(id){
                    let me = this;



 // let tienda = me.idlugar2;


/*
  Swal.fire({
  title: 'Login Form',
  html: `<input type="number" id="monto" class="swal2-input" >
  <input type="tex" id="tecnico" class="swal2-input" placeholder="Tecnico">
  <input type="text" id="nota" class="swal2-input" placeholder="nota">


  `,
  confirmButtonText: 'Sign in',
  focusConfirm: false,
  preConfirm: () => {
    const monto = Swal.getPopup().querySelector('#monto').value
    const tecnico = Swal.getPopup().querySelector('#tecnico').value
    const nota = Swal.getPopup().querySelector('#nota').value
    if (!login || !password) {
      Swal.showValidationMessage(`Please enter login and password`)
    }else{



    }
    return { login: login, password: password }
  }
}).then((result) => {
  Swal.fire(`
    Login: ${result.value.login}
    Password: ${result.value.password}
  `.trim())
})
*/

                     Swal.fire({
                    title: 'Atendido',
                     html: `
                     <div class="form-group">
                     <label>Comentario</label>
                     <input type="text" id="comentario" class="swal2-input" >
                     </div>


                      `,
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {

                        const comentario = Swal.getPopup().querySelector('#comentario').value

                 if (!comentario ) {
      Swal.showValidationMessage(`Ingrese Los Campos Vacios`)
                 }else{

                axios.post('/nota/terminado',{

                     'id': id,
                    'comentario' : comentario,



                }).then(function (response) {
                  console.log(response);
                    me.listarnota(1,'','nombre');



                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500

                        });




                }).catch(function (error) {
                    console.log(error);
                });

                             }


                    },
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                        title: `${result.value.login}'s avatar`,
                        imageUrl: result.value.avatar_url
                        })
                    }
                    })





                },

                     selectLugar2(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar2 = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },




                  terminado : function(id){




                        let me = this;
              Swal.fire({
                title: 'Esta seguro/a del termino de esta nota?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/nota/entregado',{
                          'id': id,

                        }).then(function(response){
                          me.listarnota(1,'','nombre');
                          Swal.fire(
                            'Teminado!',
                            'El nota a Cambiado a Estado a Teminado.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })




                  },


                  vernota: function(id){

                      let me=this;

                    let nota="";
                    let tecnico="";

                //Obtener los datos del venta
                var arrayVernota=[];
                var url= '/nota/ver?id=' + id;

                axios.get(url).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    arrayVernota = respuesta.notificaciones;

                    nota = arrayVernota[0]['nota'];
                    tecnico=arrayVernota[0]['tecnico'];



                Swal.fire({
  title: '<strong>nota</strong>',

  html:
    ' <b> Tecnico</b> ' +
     '<br>' +
    tecnico +
     '<br>' +
    '<b> nota</b>' +
     '<br>' +
    nota,
  showCloseButton: true,
  showCancelButton: true,
  focusConfirm: false,

})


                })
                .catch(function (error) {
                    console.log(error);
                });





                  }


        },
        mounted() {
          this.selectLugar2();
            this.listarnota(1,this.buscar,this.criterio);
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>
